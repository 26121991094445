import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { FileTreeEntry } from '../../read-models/file-tree/FileTree';
import { SerializedModelFile } from '../../apps/ModelSerializer';

interface ApplyFileTreeChangeCommandParams extends IParams {}

interface ApplyFileTreeChangeEventParams extends IParams {
  changes: FileTreeEntry[];
  deleted: FileTreeEntry[];
}

export class FileTreeChangedEvent extends EventBase {
  static eventType = 'FileTreeChangedEvent';

  constructor(
    public readonly params: ApplyFileTreeChangeEventParams,
    public readonly source = ApplyFileTreeChangesCommand,
  ) {
    super();
  }
}

export class ApplyFileTreeChangesCommand extends CommandBase<ApplyFileTreeChangeCommandParams> {
  execute(): FileTreeChangedEvent | CommandError {
    const changes: FileTreeEntry[] = [];
    const deleted: FileTreeEntry[] = [];

    if (this.model.entityRepository.getScopes().length === 0) {
      this.model.fileTree.clear();
      return new FileTreeChangedEvent({ changes: [], deleted: [] });
    }
    const currentFileTree = this.model.fileTree.getCurrentState();
    const serializedFiles = this.model.serializer.serialize();
    const newFilePaths = new Set(serializedFiles.map((file) => file.filename));
    currentFileTree.forEach((existingFile) => {
      if (!newFilePaths.has(existingFile.fileName)) {
        this.model.fileTree.delete({ id: existingFile.id });
        deleted.push(existingFile);
      }
    });
    serializedFiles.forEach((file) => {
      const fileTreeItem = this.translateSerializedFileToFileTree(file);
      if (fileTreeItem) {
        this.model.fileTree.applyChange(fileTreeItem);
        changes.push(fileTreeItem);
      }
    });
    return new FileTreeChangedEvent({ changes, deleted });
  }

  private translateSerializedFileToFileTree(file: SerializedModelFile): FileTreeEntry | undefined {
    const pathParts = file.filename.split('/').filter(Boolean);
    const text = pathParts.pop() || '';
    const parentFileName = pathParts.length === 0 ? undefined : pathParts.join('/') + '/';

    this.ensureParentExists(file.filename);
    return {
      id: file.filename,
      parentId: parentFileName,
      entityId: file.entityId,
      fileName: file.filename,
      fileType: file.fileType,
      parentFileName,
      text,
      content: file.content,
    } satisfies FileTreeEntry;
  }

  private ensureParentExists(filePath: string) {
    const pathParts = filePath.split('/').filter(Boolean);
    let currentPath = '';

    for (let i = 0; i < pathParts.length - 1; i++) {
      currentPath += pathParts[i] + '/';

      if (!this.model.fileTree.hasPath(currentPath)) {
        this.model.fileTree.applyChange({
          id: currentPath,
          parentId: i === 0 ? undefined : pathParts.slice(0, i).join('/') + '/',
          fileName: currentPath,
          fileType: 'folder',
          parentFileName: i === 0 ? undefined : pathParts.slice(0, i).join('/') + '/',
          text: pathParts[i],
          content: undefined,
        });
      }
    }
  }
}
