import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';

export class Resolver extends ConstructBase {
  style = { backgroundColor: '#9BC916', textColor: '#000000' };

  get type(): EntityType {
    return EntityType.Resolver;
  }
}
