import { Overlay } from '../overlay/overlay';
import { ScriptToggleExpansionButton } from '../../../script-toggle-expansion-button/script-toggle-expansion-button';
import { styled } from '@mui/material';
import { EntityType } from '@xspecs/single-source-model';
import { memo } from 'react';

type ScriptToggleButtonProps = {
  id: string;
  hasOverlay: boolean;
  isExpanded: boolean;
  type: EntityType;
  onClick?: () => void;
};

export const ScriptToggleButton = memo((props: ScriptToggleButtonProps) => {
  const { id, type, isExpanded, hasOverlay, onClick } = props;

  return (
    <Root>
      {hasOverlay ? <Overlay sx={overlaySx} /> : null}
      <ScriptToggleExpansionButton id={id} isExpanded={isExpanded} type={type} onClick={onClick} />
    </Root>
  );
});

ScriptToggleButton.displayName = 'ScriptToggleButton';

const Root = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  transform: 'translate(50%, -50%)',
  zIndex: 100000,
});

const overlaySx = { borderRadius: '50%' };
