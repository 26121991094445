import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { CacheEntry, LocalStorageCache } from '../auth';
import { AUTH0_LOCAL_TOKEN_KEY } from '../config/auth0/Auth0Config';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getUser() {
  const localStorageCache = new LocalStorageCache();
  const result = localStorageCache.get(AUTH0_LOCAL_TOKEN_KEY) as CacheEntry;

  if (!result || !result.decodedToken)
    return {
      id: 'anonymous',
      username: 'anonymous',
      email: 'anonymous',
    };

  const { user } = result.decodedToken;

  return {
    id: user.sub,
    username: user.name || user.nickname,
    email: user.email,
    // ip_address?: string;
    // segment?: string;
  };
}

export const LAST_SELECTED_ORGANIZATION_ID_KEY = 'lastSelectedOrganization';
export const LAST_SELECTED_WORKSPACE_BY_ORG = 'lastSelectedWorkspaceByOrg';

export const px = (value: string | number) => (value.toString().endsWith('px') ? value.toString() : `${value}px`);

export const isUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const isAutoUser = () => {
  const user = getUser();
  return user?.email?.endsWith('@on.auto') || user?.email?.endsWith('@xspecs.ai');
};
