import { AttachmentType, EntityType } from '../entities/EntityType';
import { IStore } from '../data/Store';
import { Attachment } from '../entities/assets/Attachment';
import { ConstructShape } from '../entities/constructs/ConstructBase';
import { Asset, Construct } from 'narrative-studio-sdk';

export type PaletteConstruct = Omit<Construct, 'description'>;
export type PaletteConstructsColumn = {
  caption: string;
  entities: PaletteConstruct[];
};
export type PaletteConstructsRow = PaletteConstructsColumn[];

export type PaletteAttachment = Omit<Asset, 'dataSource' | 'description'>;
export type PaletteAttachmentRow = PaletteAttachment[];

export type PaletteLayout = [PaletteConstructsRow, PaletteAttachmentRow];

export class Palette {
  private readonly constructs: PaletteConstructsRow;
  private readonly attachments: PaletteAttachmentRow;

  constructor(private readonly store: IStore) {
    this.constructs = [
      {
        caption: 'Information Flow',
        entities: [
          {
            type: EntityType.Command,
            label: 'Command',
            style: { backgroundColor: '#ACD0F7', textColor: '#000000' },
            shape: ConstructShape.SQUARE,
          },
          {
            type: EntityType.Data,
            label: 'Data',
            style: { backgroundColor: '#C1E558', textColor: '#000000' },
            shape: ConstructShape.SQUARE,
          },
          {
            type: EntityType.Event,
            label: 'Event',
            style: { backgroundColor: '#FF9C48', textColor: '#000000' },
            shape: ConstructShape.SQUARE,
          },
        ],
      },
      {
        caption: 'Business Process Model',
        entities: [
          {
            type: EntityType.Process,
            label: 'Process',
            style: { backgroundColor: '#C6A2D2', textColor: '#000000' },
            shape: ConstructShape.RECTANGLE,
          },
          {
            type: EntityType.Constraints,
            label: 'Constraints',
            style: { backgroundColor: '#FFF9B1', textColor: '#000000' },
            shape: ConstructShape.RECTANGLE,
          },
          {
            type: EntityType.ExternalSystem,
            label: 'External System',
            style: { backgroundColor: '#FFD0DF', textColor: '#000000' },
            shape: ConstructShape.RECTANGLE,
          },
        ],
      },
      {
        caption: 'Software Model',
        entities: [
          {
            type: EntityType.Resolver,
            label: 'Resolver',
            style: { backgroundColor: '#9BC916', textColor: '#000000' },
            shape: ConstructShape.RECTANGLE,
          },
          {
            type: EntityType.ReadModel,
            label: 'Read Model',
            style: { backgroundColor: '#67C6C0', textColor: '#000000' },
            shape: ConstructShape.RECTANGLE,
          },
          {
            type: EntityType.Projection,
            label: 'Projection',
            style: { backgroundColor: '#FA8A7B', textColor: '#000000' },
            shape: ConstructShape.RECTANGLE,
          },
          {
            type: EntityType.Gateway,
            label: 'Gateway',
            style: { backgroundColor: '#F685A9', textColor: '#000000' },
            shape: ConstructShape.RECTANGLE,
          },
        ],
      },
    ];
    this.attachments = [
      {
        type: EntityType.Doc,
        label: 'Doc',
        icon: Attachment.iconMap[AttachmentType.Doc],
      },
      {
        type: EntityType.Schema,
        label: 'Schema',
        icon: Attachment.iconMap[AttachmentType.Schema],
      },
      {
        type: EntityType.Query,
        label: 'Query',
        icon: Attachment.iconMap[AttachmentType.Query],
      },
    ];

    this.updateStore();
  }

  get layout(): PaletteLayout {
    return [this.constructs.filter((column) => column.entities.length > 0), this.attachments];
  }

  // TODO: these don't belong in a readmodel. smell!
  get registeredConstructs(): string[] {
    return this.constructs.flatMap((column) => {
      return column.entities.map((entity) => entity.type);
    });
  }

  get registeredAttachments(): string[] {
    return this.attachments.map((attachment) => attachment.type);
  }

  addConstructs(constructsToAdd: PaletteConstruct[]) {
    const existingColumn = this.constructs.find((column) => column.caption === 'other');
    if (existingColumn) {
      if (existingColumn.entities.find((existing) => constructsToAdd.some((added) => added.type === existing.type))) {
        return;
      }
      existingColumn.entities.push(...constructsToAdd);
    } else {
      const uniqueConstructs = constructsToAdd.filter((construct) => {
        return !this.constructs.some((column) => column.entities.some((existing) => existing.type === construct.type));
      });
      this.constructs.push({
        caption: 'other',
        entities: uniqueConstructs,
      });
    }
    this.updateStore();
  }

  addAssets(attachmentsToAdd: Asset[]) {
    attachmentsToAdd.forEach((attachment) => {
      if (this.attachments.some((existing) => existing.type === attachment.type)) {
        return;
      }
      this.attachments.push(attachment);
    });
    this.updateStore();
  }

  removeAssets(attachmentsToRemove: { type: string }[]) {
    attachmentsToRemove.forEach((attachment) => {
      const index = this.attachments.findIndex((existing) => existing.type === attachment.type);
      if (index === -1) {
        return;
      }
      this.attachments.splice(index, 1);
    });
    this.updateStore();
  }

  removeConstructs(constructsToRemove: { type: string }[]) {
    this.constructs.forEach((column) => {
      constructsToRemove.forEach((construct) => {
        const index = column.entities.findIndex((existing) => existing.type === construct.type);
        if (index === -1) {
          return;
        }
        column.entities.splice(index, 1);
      });
    });
    this.updateStore();
  }

  isAttachmentInPalette(attachmentType: string): boolean {
    return this.attachments.some((attachment) => attachment.type === attachmentType);
  }

  isValidEntityType(type: any): boolean {
    return this.registeredConstructs.includes(type) || this.registeredAttachments.includes(type);
  }

  static isPaletteConstructsColumn(data: any): data is PaletteConstructsRow {
    return (
      Array.isArray(data) && data.every((item: any) => typeof item.caption === 'string' && Array.isArray(item.entities))
    );
  }

  static isPaletteAttachmentRow(data: any): data is PaletteAttachmentRow {
    return (
      Array.isArray(data) &&
      data.every((item: any) => typeof item.caption === 'string' && typeof item.type === 'string')
    );
  }

  updateStore() {
    this.store.getState().setPalette(this.layout);
  }
}
