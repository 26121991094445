import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';
import { z } from 'zod';

export class Command extends ConstructBase {
  style = { backgroundColor: '#ACD0F7', textColor: '#000000' };

  //static version = '2.0.0'; // renamed Action to Command;
  static version = '2.0.1'; // Replaces transitions with edges;
  static schema = ConstructBase.baseSchema.extend({
    width: z.number().default(80),
  });

  get type(): EntityType {
    return EntityType.Command;
  }
}
