import { SingleSourceModel } from '../SingleSourceModel';
import {
  Connection,
  Edge,
  EdgeChange,
  EdgeSelectionChange,
  Node,
  NodeAddChange,
  NodeChange,
  NodeSelectionChange,
  ResizeParamsWithDirection,
  XYPosition,
} from '@xyflow/react'; // TODO rethink these imports
import { sid } from '@xspecs/short-id';
import { MoveEntitiesCommand } from '../commands/entities/MoveEntitiesCommand';
import { Position, WithId } from '../types';
import { InteractorResponse } from './types';
import { DEBUG_CONFIG } from '../debug-config';
import { logger } from '@xspecs/logger';
import { Awareness } from 'y-protocols/awareness';
import { EntityBase } from '../entities/EntityBase';
import { Attachment } from '../entities/assets/Attachment';
import { AttachmentType } from '../entities/EntityType';
import { UploadType } from '../entities/assets/Upload';
import { IStore } from '../data/Store';
import { AddEntityCommand } from '../commands/entities/AddEntityCommand';
import { DeselectAllEntitiesCommand } from '../commands/selections/DeselectAllEntitiesCommand';
import { UpdateEntitiesSelectionsCommand } from '../commands/selections/UpdateEntitiesSelectionsCommand';
import { AddRelationshipCommand } from '../commands/relationships/AddRelationshipCommand';
import { DragEntitiesCommand } from '../commands/entities/DragEntitiesCommand';
import { SelectAllEntitiesCommand } from '../commands/selections/SelectAllEntitiesCommand';
import { ClearErrorCommand } from '../commands/errors/ClearErrorCommand';
import { ToggleScriptVisibilityCommand } from '../commands/scripts/ToggleScriptVisibilityCommand';
import { TestCodeEvent } from '../TestCodeHandler';
import { DeleteEntitiesCommand } from '../commands/entities/DeleteEntitiesCommand';
import { CommandError } from '../ErrorStore';
import { ToggleAttachmentsExpandedCommand } from '../commands/attachments/ToggleAttachmentsExpanded';
import { ResizeEntityCommand } from '../commands/entities/ResizeEntityCommand';
import { CreateUploadCommand } from '../commands/entities/CreateUploadCommand';
import { AddToolbarSelectedEntityCommand } from '../commands/toolbar/AddToolbarSelectedEntityCommand';
import { EntityParserFactory } from '../entities/EntityParserFactory';
import { UpdateRelationshipCommand } from '../commands/relationships/UpdateRelationshipCommand';
import { AssetBase } from '../entities/assets/AssetBase';
import { ConstructBase } from '../entities/constructs/ConstructBase';
import { HighlightDropTargetsCommand } from '../commands/entities/HighlightDropTargetsCommand';

export class CanvasInteractor {
  private dxDy: XYPosition = { x: 0, y: 0 };
  private mousePosition: XYPosition = { x: 0, y: 0 };
  private dragStartPosition: XYPosition = { x: 0, y: 0 };
  private flowPosition: XYPosition = { x: 0, y: 0 };

  private get store(): IStore {
    return this.storeWrapper.getState();
  }

  constructor(
    private readonly model: SingleSourceModel,
    private readonly storeWrapper: IStore,
    private readonly awareness: Awareness,
  ) {}

  addSelectedEntity(position: XYPosition, userSub: string) {
    this.resetDxDy();
    const entityToInsert = this.model.toolbar.getSelectedEntityType();
    const id = sid();
    if (!entityToInsert) return;
    this.model.messageBus.send(AddToolbarSelectedEntityCommand, {
      id: id,
      position,
      userSub: userSub,
    });
    if (EntityParserFactory.isInstanceOfBase(entityToInsert, AssetBase)) {
      const entity = this.model.entityRepository.get(id)!;
      this.openUploadFileModal(entity);
    }
    this.store.setSelectionMode();
  }

  onDrop(flowPosition: XYPosition, type: string, userSub: string) {
    this.addSelectedEntity(flowPosition, userSub);
  }

  onDropFiles(
    flowPosition: XYPosition,
    file: File,
    url: string,
    assetId: string,
    metadata?: { width: number; height: number },
  ) {
    const type = file.type.startsWith('image') ? UploadType.Image : UploadType.File;

    const attachmentId = sid();
    this.model.messageBus.send(CreateUploadCommand, {
      assetId,
      id: attachmentId,
      name: file.name,
      position: flowPosition,
      type,
      url: url,
      metadata,
    });
  }

  openUploadFileModal(entity: EntityBase) {
    if (entity instanceof Attachment && entity.subType === AttachmentType.Upload && !entity.asset) {
      this.store.setShowUploadFileModal(entity.id);
    }
  }

  onMouseUp(flowPosition: XYPosition, userSub: string) {
    this.addSelectedEntity(flowPosition, userSub);
  }

  onNodeChanges(nodeChanges: NodeChange[]) {
    if (nodeChanges.length === 0) return;
    // can we can safely assume that all changes are of the same type? Assuming yes for now
    const changeType = nodeChanges[0].type;
    if (changeType === 'select') {
      // TODO perhaps we should ignore selections here and instead translate intent depending on what the user clicked?
      this.model.messageBus.send(UpdateEntitiesSelectionsCommand, {
        entitySelections: nodeChanges.filter((nodeChange) => nodeChange.type === 'select') as NodeSelectionChange[],
      });
      return;
    }

    if (changeType === 'add') {
      nodeChanges
        .filter((n) => n.type == 'add')
        .forEach((change: NodeAddChange) => {
          const { item } = change;
          if (!item.type || !Attachment.isValidAttachmentType(item.type)) return;
          this.model.messageBus.send(AddEntityCommand, {
            id: item.id,
            name: (item.data.name as string) ?? '',
            type: item.type as any,
            position: item.position,
          });
          const entity = this.model.entityRepository.get(item.id)!;
        });
    }
  }

  onMouseLeave() {
    this.deleteCursor();
  }

  // ## ================== EDGE INTERACTIONS ================== ##

  onConnect(connection: Connection): InteractorResponse {
    if (connection.source && connection.target) {
      const ret = this.model.messageBus.send(AddRelationshipCommand, {
        sourceId: connection.source,
        targetId: connection.target,
        sourceHandle: connection.sourceHandle,
        targetHandle: connection.targetHandle,
      });
      if (ret instanceof Error) {
        return {
          action: 'snackbar',
          params: { message: ret.message, severity: 'warning' },
        };
      }
    }
  }

  onReconnect(oldEdge: Edge, newConnection: Connection) {
    this.model.messageBus.send(UpdateRelationshipCommand, {
      id: oldEdge.id,
      sourceId: newConnection.source,
      targetId: newConnection.target,
      sourceHandle: newConnection.sourceHandle || undefined,
      targetHandle: newConnection.targetHandle || undefined,
    });
  }

  onConnectStart(params) {
    this.model.messageBus.send(DeselectAllEntitiesCommand, {});
  }

  onEdgesChange(edgeChanges: EdgeChange[]) {
    if (edgeChanges.length === 0) return;
    const type = edgeChanges[0].type;
    if (type === 'select') {
      this.model.messageBus.send(UpdateEntitiesSelectionsCommand, {
        entitySelections: edgeChanges as EdgeSelectionChange[],
      });
      return;
    }
  }

  // ## ================== CUT/COPY/PASTE + KEYBOARD SHORTCUTS ================== ##

  undo() {
    this.model.undo();
  }

  redo() {
    this.model.redo();
  }

  onCut() {
    void this.model.clipboard.cut();
  }

  onCopy() {
    void this.model.clipboard.copy();
  }

  onPaste() {
    this.model.messageBus.send(DeselectAllEntitiesCommand, {});
    this.model.clipboard.paste(this.flowPosition).then((pasted) => {
      this.flowPosition = { x: this.flowPosition.x + 20, y: this.flowPosition.y + 20 };
      this.model.messageBus.send(UpdateEntitiesSelectionsCommand, {
        entitySelections: pasted.map((entity) => ({ id: entity.id, selected: true })),
      });
    });
  }

  selectEntity(id: string) {
    this.model.messageBus.send(UpdateEntitiesSelectionsCommand, { entitySelections: [{ id, selected: true }] });
  }

  onDuplicate() {
    const duplicatedEntities = this.model.clipboard.duplicate(this.flowPosition);
    this.model.messageBus.send(DeselectAllEntitiesCommand, {});
    this.model.messageBus.send(UpdateEntitiesSelectionsCommand, {
      entitySelections: duplicatedEntities.map((entity) => ({ id: entity.id, selected: true })),
    });
    this.flowPosition = { x: this.flowPosition.x + 20, y: this.flowPosition.y + 20 };
  }

  onSelectAll() {
    this.model.messageBus.send(SelectAllEntitiesCommand, {});
  }

  onDeSelectAll() {
    this.model.messageBus.send(DeselectAllEntitiesCommand, {});
  }

  onEscape() {
    this.model.messageBus.send(DeselectAllEntitiesCommand, {});
    this.store.setSelectionMode();
  }

  // ## ================== HOVER TRACKING ================== ##

  // ## ================== MOUSE TRACKING ================== ##

  onDragOver(mousePosition: XYPosition, flowPosition: XYPosition) {
    if (mousePosition.x === this.mousePosition.x && mousePosition.y === this.mousePosition.y) return;
    this.setMouseAndFlowPosition(mousePosition, flowPosition);
    const type = this.model.toolbar.getSelectedEntityType();
    if (type) {
      this.model.messageBus.send(HighlightDropTargetsCommand, {
        type: type,
        cursorPosition: flowPosition,
      });
    }
  }

  onNodeClick(mousePosition: Position, flowPosition: Position, id: string, userSub: string) {
    this.log('onNodeClick');
    this.setMouseAndFlowPosition(mousePosition, flowPosition);
    const entity = this.model.entityRepository.get(id);
    if (!entity) return;
    this.onMouseUp(flowPosition, userSub);
  }

  onNodeDragStart(mousePosition: XYPosition, flowPosition: XYPosition, nodes: Node[]) {
    this.resetDxDy();
    this.setMouseAndFlowPosition(mousePosition, flowPosition);
    this.setDragStartPosition(flowPosition, 'onNodeDragStart');
    this.model.messageBus.send(UpdateEntitiesSelectionsCommand, {
      entitySelections: nodes.map((n) => ({ id: n.id, selected: true })),
    });
  }

  onNodeDrag(mousePosition: XYPosition, flowPosition: XYPosition, nodes: Node[], params: { isModPressed: boolean }) {
    this.setMouseAndFlowPosition(mousePosition, flowPosition);
    this.setCursor(flowPosition);
    this.setDxDy(flowPosition, 'onNodeDrag');
    this.model.messageBus.send(DragEntitiesCommand, {
      entityIds: nodes.map((n) => n.id),
      dxDy: this.dxDy,
      isModPressed: params.isModPressed,
      cursorPosition: flowPosition,
    });
  }

  onNodeDragStop(
    mousePosition: XYPosition,
    flowPosition: XYPosition,
    nodes: Node[],
    params: { isModPressed: boolean },
  ) {
    this.setMouseAndFlowPosition(mousePosition, flowPosition);
    this.model.messageBus.send(MoveEntitiesCommand, {
      entityIds: nodes.map((c) => (c as WithId).id),
      dxDy: this.dxDy,
      isModPressed: params.isModPressed,
      cursorPosition: flowPosition,
    });
    this.resetDxDy();
  }

  onMouseMove(mousePosition: XYPosition, flowPosition: XYPosition) {
    this.setMouseAndFlowPosition(mousePosition, flowPosition);
    this.setCursor(flowPosition);
    // this.model.messageBus.send(MouseMoveCommand, {
    //   type: this.model.toolbar.getSelectedEntityType(),
    //   cursorPosition: flowPosition,
    // });
  }

  toggleEntityVisibilityExpanded(id: string) {
    const entity = this.model.entityRepository.get(id);
    if (entity instanceof ConstructBase && entity.script) {
      this.model.messageBus.send(ToggleScriptVisibilityCommand, { entityId: id });
    } else {
      this.model.messageBus.send(ToggleAttachmentsExpandedCommand, { id });
    }
  }

  private setMouseAndFlowPosition(mousePosition: XYPosition, flowPosition: XYPosition) {
    this.mousePosition = mousePosition;
    this.flowPosition = flowPosition;
  }

  private setDxDy(flowPosition: XYPosition, caller: string) {
    this.dxDy = {
      x: flowPosition.x - this.dragStartPosition.x,
      y: flowPosition.y - this.dragStartPosition.y,
    };
    this.log(`${caller} => setDxDy`);
  }

  private resetDxDy() {
    this.dxDy = { x: 0, y: 0 };
    this.clearDdxDy();
    this.log(`resetDxDy`);
  }

  private setDragStartPosition(dragStartPosition: XYPosition, caller: string) {
    this.dragStartPosition = dragStartPosition;
    this.log(`${caller} => setDragStartPosition`);
  }

  // ## ================== AWARENESS ================== ##

  private clearDdxDy() {
    this.awareness.setLocalStateField('changeType', 'position');
    this.awareness.setLocalStateField('dxDy', null);
  }

  private setCursor(position: XYPosition) {
    this.awareness.setLocalStateField('changeType', 'position');
    this.awareness.setLocalStateField('position', position);
    this.awareness.setLocalStateField('dxDy', this.dxDy);
  }

  private deleteCursor() {
    this.awareness.setLocalStateField('changeType', 'position');
    this.awareness.setLocalStateField('position', null);
    this.awareness.setLocalStateField('dxDy', null);
  }

  // ## ================== SELECTION ================== ##

  onSelectionChange(selection: any) {
    if (DEBUG_CONFIG.interactor) logger.log('onSelectionChange', selection);
  }

  onSelectionStart(event: React.MouseEvent<Element, MouseEvent>) {
    this.model.messageBus.send(DeselectAllEntitiesCommand, {});
  }

  onSelectionEnd(event: React.MouseEvent<Element, MouseEvent>) {
    if (DEBUG_CONFIG.interactor) logger.log('onSelectionEnd');
  }

  // ## ================== OTHER ================== ##
  log(caller?: string, min?: boolean) {
    if (!DEBUG_CONFIG.interactor) return;
    logger.log(
      `\n${caller}`,
      min ?? `\n  dragStartPosition: ${this.dragStartPosition.x}, ${this.dragStartPosition.y}`,
      min ?? `\n  flowPosition: ${this.flowPosition.x}, ${this.flowPosition.y}`,
      min ?? `\n  dxDy: ${this.dxDy.x}, ${this.dxDy.y}`,
      min ?? `\n  mousePosition: ${this.mousePosition.x}, ${this.mousePosition.y}`,
    );
  }

  clearError(errors: CommandError | CommandError[]) {
    this.model.messageBus.send(ClearErrorCommand, { errors: errors });
  }

  onDelete() {
    this.model.messageBus.send(DeleteEntitiesCommand, {});
  }

  onTestCode() {
    this.model.messageBus.handle(new TestCodeEvent());
  }

  resizeEntity(id: string, params: ResizeParamsWithDirection) {
    this.model.messageBus.send(ResizeEntityCommand, {
      entityId: id,
      width: params.width,
      height: params.height,
      x: params.x,
      y: params.y,
    });
  }
}
