import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';

export class Interface extends ConstructBase {
  style = { backgroundColor: '#FFFFFF', textColor: '#000000' };

  get type(): EntityType {
    return EntityType.Interface;
  }
}
