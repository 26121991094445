import { SerializationRule } from 'narrative-studio-sdk/dist/types/scheme/SerializationRule';
import { ConstructBase } from '../../entities/constructs/ConstructBase';
import { AssetBase } from '../../entities/assets/AssetBase';
import { Edge } from '../../entities/transitions/Edge';
import { EntityBase } from '../../entities/EntityBase';

const createGetEntityName = () => {
  return (entity: { name?: string }) => {
    return entity.name ? entity.name.trim().toLowerCase().replace(/\s+/g, '-') : '';
  };
};

export const eventModelSerializationRules: SerializationRule<EntityBase>[] = [
  {
    match: (entity) => entity instanceof AssetBase,
    serialize: (entity: AssetBase) => {
      const getEntityName = createGetEntityName();
      const assetFolder = `assets/${entity.type.toLowerCase()}--${getEntityName(entity)}/`;

      return [
        { entityId: entity.id, filename: assetFolder, fileType: 'folder' },
        {
          entityId: entity.id,
          filename: `${assetFolder}metadata.json`,
          content: JSON.stringify(entity.serialize(), null, 2),
          fileType: 'json',
        },
        {
          entityId: entity.id,
          filename: `${assetFolder}payload.json`,
          content: JSON.stringify(entity.payload ?? {}, null, 2),
          fileType: 'json',
        },
      ];
    },
  },
  {
    match: (entity) => entity.type === 'EventModel',
    serialize: (entity: ConstructBase) => {
      const getEntityName = createGetEntityName();
      const eventModelName = getEntityName(entity);
      const eventModelFolder = `entities/${eventModelName}.event-model/`;

      const rootFiles = [
        {
          entityId: entity.id,
          filename: `${eventModelFolder}metadata.json`,
          content: JSON.stringify(entity.serialize(), null, 2),
          fileType: 'json',
        },
        {
          entityId: entity.id,
          filename: `${eventModelFolder}payload.json`,
          content: JSON.stringify(entity.payload ?? {}, null, 2),
          fileType: 'json',
        },
      ];

      const slicesFolders = entity.script.frameGroups.slice(1).map((_, index) => ({
        entityId: entity.id,
        filename: `${eventModelFolder}slice.${index}/`,
        fileType: 'folder',
      }));

      const entityFiles = entity.script.frames.flatMap((frame) =>
        frame.entities.flatMap((laneEntities) =>
          laneEntities.flatMap((childEntity) => {
            const entityName = `${childEntity.type.toLowerCase()}--${getEntityName(childEntity)}`;
            const frameGroupIndex = entity.script.frameGroups.findIndex((fg) => fg.frameIds.includes(frame.id));
            const entityFolder = `${eventModelFolder}slice.${frameGroupIndex - 1}/${entityName}/`;

            return [
              { entityId: childEntity.id, filename: entityFolder, fileType: 'folder' },
              {
                entityId: childEntity.id,
                filename: `${entityFolder}metadata.json`,
                content: JSON.stringify(childEntity.serialize(), null, 2),
                fileType: 'json',
              },
              {
                entityId: childEntity.id,
                filename: `${entityFolder}payload.json`,
                content: JSON.stringify(childEntity.payload ?? {}, null, 2),
                fileType: 'json',
              },
            ];
          }),
        ),
      );

      return [
        { entityId: entity.id, filename: eventModelFolder, fileType: 'folder' },
        ...rootFiles,
        ...slicesFolders,
        ...entityFiles.flat(),
      ];
    },
  },
  {
    match: (entity) => entity.type === 'Label',
    serialize: (entity) => {
      const labelFolder = `labels/${entity.name.toLowerCase().replace(/\s+/g, '-')}/`;

      return [
        { entityId: entity.id, filename: labelFolder, fileType: 'folder' },
        {
          entityId: entity.id,
          filename: `${labelFolder}metadata.json`,
          content: JSON.stringify(entity.serialize(), null, 2),
          fileType: 'json',
        },
        {
          entityId: entity.id,
          filename: `${labelFolder}payload.json`,
          content: JSON.stringify(entity.payload ?? {}, null, 2),
          fileType: 'json',
        },
      ];
    },
  },
  {
    match: (entity) => entity instanceof Edge,
    serialize: (entity: Edge) => {
      const getEntityName = createGetEntityName();
      const sourceName = getEntityName(entity.source);
      const targetName = getEntityName(entity.target);
      const edgeFolder = `transitions/${entity.source.type.toLowerCase()}--${sourceName}__${entity.target.type.toLowerCase()}--${targetName}/`;
      return [
        { entityId: entity.id, filename: edgeFolder, fileType: 'folder' },
        {
          entityId: entity.id,
          filename: `${edgeFolder}metadata.json`,
          content: JSON.stringify(entity.serialize(), null, 2),
          fileType: 'json',
        },
        {
          entityId: entity.id,
          filename: `${edgeFolder}payload.json`,
          content: JSON.stringify(entity.payload ?? {}, null, 2),
          fileType: 'json',
        },
      ];
    },
  },
];
