import { IStore } from '../../data/Store';
import { FileTreeItem, FileTreeState } from './FileTree.types';

const defaultFileTreeState: FileTreeState = {
  rootId: null,
  items: [],
};

export type FileTreeEntry = {
  id: string;
  fileName: string;
  fileType: string;
  entityId?: string;
  parentFileName?: string;
  parentId: string | undefined;
  text: string;
  content: string | undefined;
};

export class FileTree {
  public cache: Record<string, FileTreeEntry> = {};
  public rootId: string = 'file-tree-root';

  constructor(private readonly store: IStore) {
    this.store.getState().setFileTree({ ...defaultFileTreeState });
  }

  applyChange(entity: FileTreeEntry) {
    const existingEntity = this.cache[entity.id];
    if (existingEntity && this.isEqual(existingEntity, entity)) {
      return;
    }
    this.cache[entity.id] = entity;
    this.updateStore();
  }

  getCurrentState(): FileTreeEntry[] {
    return Object.values(this.cache);
  }

  delete({ id }: { id: string }) {
    delete this.cache[id];
    this.updateStore();
  }

  updateStore() {
    const items: FileTreeItem[] = Object.entries(this.cache).map(([key, entity]) => {
      return {
        id: entity.id,
        parent: entity.parentId ?? this.rootId,
        droppable: entity.fileType === 'folder',
        text: entity.text,
        data: {
          icon: entity.fileType,
        },
      } satisfies FileTreeItem;
    });
    this.store.getState().setFileTree({
      rootId: this.rootId,
      items: items,
    });
  }

  hasPath(path: string): boolean {
    return Object.keys(this.cache).some((key) => key.startsWith(path));
  }

  clear() {
    this.store.getState().setFileTree({ ...defaultFileTreeState });
  }

  private isEqual(a: FileTreeEntry, b: FileTreeEntry): boolean {
    return (
      a.id === b.id &&
      a.fileName === b.fileName &&
      a.fileType === b.fileType &&
      a.entityId === b.entityId &&
      a.parentFileName === b.parentFileName &&
      a.parentId === b.parentId &&
      a.text === b.text &&
      a.content === b.content
    );
  }

  dispose() {
    this.clear();
  }
}
