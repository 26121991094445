import { MessageBus } from '../../commands/framework/MessageBus';

export class FileTreeProjection {
  constructor(private readonly messageBus: MessageBus) {
    // this.messageBus.subscribe(
    //   [ChangesSavedEvent],
    //   function (event: ChangesSavedEvent) {
    //     const isPositionChange = event.changes.updated.every((change) =>
    //       change.modifiedProperties.every((prop) => prop.startsWith('position.')),
    //     );
    //     if (
    //       event.changes.added.length === 0 &&
    //       event.changes.deleted.length === 0 &&
    //       event.changes.updated.length === 0
    //     ) {
    //       return;
    //     }
    //     if (event.changes.added.length === 0 && event.changes.deleted.length === 0 && isPositionChange) {
    //       return;
    //     }
    //     this.messageBus.sendInternal(ApplyFileTreeChangesCommand, { changes: event.changes });
    //   }.bind(this),
    // );
  }
}
