import { Box, SxProps, Theme } from '@mui/material';
import { useMonaco } from '../../spec/use-monaco';
import { useElementSize } from '@mantine/hooks';
import { default as Editor } from '@monaco-editor/react';
import { FileStatus } from '../../file-status/file-status';
import { useMemo } from 'react';
import { editor } from 'monaco-editor';
import { useActiveOrganization } from '../../../hooks/use-active-organization';

type SchemaEditorProps = {
  id: string;
  onEditorChange?: (value: string) => void;
};

export const JsonEditor = (props: SchemaEditorProps) => {
  const { id, onEditorChange } = props;
  const { organization } = useActiveOrganization();
  const fileId = `${organization?.id}/${id}`;

  const { onMount } = useMonaco({ id: id, placeholder: 'Type here...', initialValue: '{}' });

  const { ref, height, width } = useElementSize<HTMLDivElement>();

  const options = useMemo<editor.IStandaloneEditorConstructionOptions>(
    () => ({
      language: 'json',
      theme: 'default',
      wordWrap: 'on',
      'semanticHighlighting.enabled': true,
      lineNumbers: 'off',
      minimap: { enabled: false },
      scrollBeyondLastLine: false,
      scrollBeyondLastColumn: 0,
      fontFamily: "'JetBrains Mono'",
      scrollbar: {
        horizontal: 'hidden',
        vertical: 'hidden',
      },
      renderLineHighlight: 'none',
      overviewRulerBorder: false,
      glyphMargin: false,
      defaultColorDecorators: false,
      overviewRulerLanes: 0,
      folding: true,
      guides: {
        indentation: false,
      },
      renderWhitespace: 'none',
      quickSuggestions: false,
    }),
    [],
  );

  return (
    <Box sx={rootSx} ref={ref}>
      <Editor width={width} height={height} options={options} onMount={onMount} onChange={onEditorChange} />
      <FileStatus fileId={fileId} />
    </Box>
  );
};

const rootSx: SxProps<Theme> = {
  backgroundColor: 'background.paper',
  height: '100%',
};
