import { Fragment, memo, useCallback } from 'react';
import { EdgeLabelRenderer, Handle, Node, NodeProps, Position, useReactFlow } from '@xyflow/react';
import { Overlay } from '../../constructs/overlay/overlay';
import { ScriptNodeData } from '@xspecs/single-source-model';
import { Script } from '../script';

export const ScriptNode = (props: NodeProps<Node<ScriptNodeData>>) => {
  const { data, positionAbsoluteX, positionAbsoluteY } = props;

  const { screenToFlowPosition } = useReactFlow();

  const mouseToXY = useCallback(
    (event: { clientX: number; clientY: number }) => {
      const mouseFlowPosition = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const x = mouseFlowPosition.x - positionAbsoluteX;
      const y = mouseFlowPosition.y - positionAbsoluteY;

      return { x, y };
    },
    [positionAbsoluteX, positionAbsoluteY, screenToFlowPosition],
  );
  return (
    <Fragment>
      {data.hasOverlay && <Overlay />}
      <Script
        mouseToXY={mouseToXY}
        frameGroups={data.frameGroups}
        laneGroups={data.laneGroups}
        width={data.width}
        height={data.height}
        position={{
          x: positionAbsoluteX,
          y: positionAbsoluteY,
        }}
        HeaderContainer={EdgeLabelRenderer}
      />
      <Handles />
    </Fragment>
  );
};

const Handles = memo(() => {
  return (
    <Fragment>
      <Handle id={Position.Left} type="source" position={Position.Left} />
      <Handle id={Position.Right} type="source" position={Position.Right} />
      <Handle id={Position.Top} type="source" position={Position.Top} />
      <Handle id={Position.Bottom} type="source" position={Position.Bottom} />
    </Fragment>
  );
});
Handles.displayName = 'Handles';
