import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';
import { NarrativeScript } from '../scripts/NarrativeScript';
import { scriptBaseSchema } from '../scripts/ScriptBaseSchema';
import { z } from 'zod';

export class Narrative extends ConstructBase {
  style = { backgroundColor: '#F0E1F9', textColor: '#000000' };

  static schema = ConstructBase.baseSchema.extend({
    script: z.lazy(() => scriptBaseSchema),
  });

  static version = '2.0.4'; // Adds edges to the script;

  public script: NarrativeScript;

  // static parse(data: object, omitReferences = false): Narrative {
  //   // const validatedData = Narrative.schema.parse(data);
  //   // const narrative = ConstructBase.parseBase.call(Narrative, validatedData);
  //   // narrative.script = validatedData.script;
  //   // return narrative;
  //   if (omitReferences) {
  //     const validatedData = Narrative.schema.omit({ script: true }).parse(data);
  //     return ConstructBase.parseBase.call(Narrative, validatedData);
  //   }
  //   return super.parseBase<Narrative>(data, Narrative.schema);
  // }

  // static parse(data: unknown): Narrative {
  //   return ConstructBase.parseBase.call(Narrative, data, Narrative.schema);
  // }

  // isValid(): boolean {
  //   return Narrative.schema.safeParse(this).success;
  // }

  get type(): EntityType {
    return EntityType.Narrative;
  }

  get scriptType(): string {
    return 'NarrativeScript';
  }

  // removeSubscript() {
  //   this.script = null!;
  // }

  static references = ['script'];

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      //script: this?.script?.serialize(true),
    };
  }
}
