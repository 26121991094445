import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';

export class Gateway extends ConstructBase {
  style = { backgroundColor: '#F685A9', textColor: '#000000' };

  get type(): EntityType {
    return EntityType.Gateway;
  }
}
