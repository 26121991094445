import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const Translation: FC<IconComponentProps> = ({ color, borderColor }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1626_5527)">
        <path
          d="M9.33325 2.66659C9.33325 1.93325 9.93325 1.33325 10.6666 1.33325M13.3333 1.33325C14.0666 1.33325 14.6666 1.93325 14.6666 2.66659M14.6666 5.33325C14.6666 6.06659 14.0666 6.66659 13.3333 6.66659M10.6666 6.66659C9.93325 6.66659 9.33325 6.06659 9.33325 5.33325M1.99992 4.66659L3.99992 6.66659M3.99992 6.66659L5.99992 4.66659M3.99992 6.66659V3.33325C3.99992 2.19992 4.86659 1.33325 5.99992 1.33325H6.66659M2.66659 9.33325H5.33325C6.06963 9.33325 6.66659 9.93021 6.66659 10.6666V13.3333C6.66659 14.0696 6.06963 14.6666 5.33325 14.6666H2.66659C1.93021 14.6666 1.33325 14.0696 1.33325 13.3333V10.6666C1.33325 9.93021 1.93021 9.33325 2.66659 9.33325Z"
          stroke="#71717A"
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1626_5527">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
