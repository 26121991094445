import { ConstructFile } from '../dock-view/dock-view';
import {
  ChangeEvent,
  Dispatch,
  FC,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ChevronRight } from 'lucide-react';
import { clsx } from 'clsx';
import { DndProvider } from 'react-dnd';
import { getBackendOptions, MultiBackend, NodeModel, Tree } from '@minoru/react-dnd-treeview';
import styles from './tree-view.module.css';
import { cn } from '../../utils';
import { Icon } from '@xspecs/design-system';

export interface TreeViewProps {
  rootId: string;
  files: NodeModel[];
  exitEditMode: (id: string) => void;
  onNameChange: (id: string, name: string) => void;
  setTreeData: Dispatch<SetStateAction<NodeModel[]>>;
  disabled?: boolean;
}

type NodeProps = {
  node: NodeModel<ConstructFile>;
  depth: number;
  isOpen: boolean;
  onToggle: (id: NodeModel['id']) => void;
  exitEditMode: (id: string | number) => void;
  onNameChange: (id: string | number, name: string) => void;
};

export const CustomNode: FC<NodeProps> = (props) => {
  const { droppable, data, id } = props.node;
  const indent = props.depth * 24;
  const inputRef = useRef<HTMLInputElement>(null);
  const [newFile, setNewFile] = useState('');

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [inputRef]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewFile(event.target.value);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && id) {
      props.exitEditMode(id);
      props.onNameChange(id, newFile);
    }
  };

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (!id) return;
    props.exitEditMode(id);
    props.onNameChange(id, newFile);
  };

  const handleToggle = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    props.onToggle(props.node.id);
  };

  return (
    <div
      style={{ paddingInlineStart: indent }}
      className={clsx(
        'relative flex items-center p-2 rounded-md hover:bg-sidebar-accent group/item',
        data?.active && 'bg-sidebar-accent',
      )}
    >
      <div>
        {props.node.droppable ? (
          <div onClick={handleToggle}>
            <ChevronRight className={`size-4 text-icon ml-1 ${props.isOpen ? 'rotate-90' : ''}`} />
          </div>
        ) : (
          <div className={cn('w-4 height-4 ml-1', props.node.parent !== 0 && 'ml-0')} />
        )}
      </div>
      {/*{props.node.droppable ? (*/}
      {/*  <Folder size="16" className={`text-icon`} />*/}
      {/*) : (*/}
      <Icon name={data?.icon || ''} width={16} height={16} />
      {/*)}*/}
      {data?.editMode ? (
        <input
          type="text"
          className="text-sm ml-1 leading-3.5 text-secondary outline-0"
          ref={inputRef}
          value={newFile}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />
      ) : (
        <span
          className={clsx(
            'text-sm leading-3.5 ml-1 group-hover/item:text-primary',
            data?.active ? 'text-primary font-medium' : 'text-secondary',
          )}
        >
          {props.node.text}
        </span>
      )}
    </div>
  );
};

export const TreeView = (props: TreeViewProps) => {
  const { files, exitEditMode, onNameChange, setTreeData, disabled, rootId } = props;
  const handleDrop = (newTree: NodeModel[]) => setTreeData(newTree);

  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <div className="overflow-y-auto w-[95%] h-full px-1 py-0.5">
        <Tree
          tree={files}
          rootId={rootId}
          canDrag={() => !disabled}
          canDrop={() => !disabled}
          render={(node: NodeModel<ConstructFile>, { depth, isOpen, onToggle }) => (
            <CustomNode
              node={node}
              depth={depth}
              isOpen={isOpen}
              onToggle={onToggle}
              exitEditMode={exitEditMode}
              onNameChange={onNameChange}
            />
          )}
          onDrop={handleDrop}
          classes={{
            root: styles.treeRoot,
            draggingSource: styles.draggingSource,
            dropTarget: styles.dropTarget,
          }}
        />
      </div>
    </DndProvider>
  );
};
