import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const Files: FC<IconComponentProps> = () => {
  return (
    <path
      d="M20 7H17C16.4696 7 15.9609 6.78929 15.5858 6.41421C15.2107 6.03914 15 5.53043 15 5V2M3 7.6001V20.4001C3 20.8244 3.16857 21.2314 3.46863 21.5315C3.76869 21.8315 4.17565 22.0001 4.6 22.0001H14.4M9 18C8.46957 18 7.96086 17.7893 7.58579 17.4142C7.21071 17.0391 7 16.5304 7 16V4C7 3.46957 7.21071 2.96086 7.58579 2.58579C7.96086 2.21071 8.46957 2 9 2H16L20 6V16C20 16.5304 19.7893 17.0391 19.4142 17.4142C19.0391 17.7893 18.5304 18 18 18H9Z"
      stroke="black"
      strokeOpacity="0.54"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
};
