import { Box, Stack } from '@mui/material';
import { Allotment, AllotmentHandle } from 'allotment';
import { useOutlet, useParams } from 'react-router-dom';
import { SingleSourceModel } from '../../components/single-source-model';
import { SingleSourceModelWrapper } from '../../wrappers/single-source-model-wrapper/single-source-model-wrapper';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { SingleSourceFilters } from '../../components/single-source-model/filters/single-source-filters';
import { getToken } from '../../utils/getToken';
import { useApplication } from '../../wrappers/application-context/application-context';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { useAuth } from '../../auth';
import { Sidebar } from '../../components/sidebar/sidebar';
import { LAST_SELECTED_WORKSPACE_BY_ORG } from '../../lib/utils';
import { useActiveOrganization } from '../../hooks/use-active-organization';

export const WorkspaceRoute = () => {
  const { entityId } = useParams();

  const entityBaseId = entityId ? entityId.split('-')[0] : '';
  const outlet = useOutlet({ entityId: entityBaseId });
  const ref = useRef<AllotmentHandle>(null);
  const { application } = useApplication();

  const assetExplorer = useSingleSourceStore.use.assetExplorer();
  const explorer = useSingleSourceStore.use.explorer();

  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();
  const isLoaded = useSingleSourceStore.use.isLoaded();
  const collapseSidebar = useSingleSourceStore.use.collapseSidebar();
  const setCollapseSidebar = useSingleSourceStore.use.setCollapseSidebar();
  const { user } = useAuth();
  const isFirstRender = useRef(true);

  const onDragEnd = useCallback((sizes: number[]) => {
    localStorage.setItem('allotments', JSON.stringify(sizes));
  }, []);

  const defaultSizes = useMemo(() => {
    const DEFAULT = [300, 2000, 200];
    const x = JSON.parse(localStorage.getItem('allotments') ?? 'null');
    if (!x || x.length !== DEFAULT.length) localStorage.setItem('allotments', JSON.stringify(DEFAULT));
    return x ?? DEFAULT;
  }, []);

  const onCollapseSidebar = useCallback(() => {
    setCollapseSidebar(!collapseSidebar);
  }, [collapseSidebar, setCollapseSidebar]);

  useEffect(() => {
    const token = getToken();
    if (isLoaded && workspace && application && token) {
      application.changeContext({
        token,
        user,
        scope: workspace.id,
      });
    }
  }, [application, isLoaded, user, workspace]);

  useEffect(() => {
    if (!workspace || !organization) return;
    const currentLastSelectedWorkspaceByOrg = JSON.parse(localStorage.getItem(LAST_SELECTED_WORKSPACE_BY_ORG) || '{}');
    localStorage.setItem(
      LAST_SELECTED_WORKSPACE_BY_ORG,
      JSON.stringify({
        ...currentLastSelectedWorkspaceByOrg,
        [organization.id]: workspace.id,
      }),
    );
  }, [organization, workspace]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const [sidebar = 300, canvas = 700, detailsPanel = 200] = JSON.parse(localStorage.getItem('allotments') ?? '[]');
    if (collapseSidebar) {
      ref.current?.resize([64, canvas + sidebar - 64, detailsPanel]);
    } else {
      ref.current?.resize([sidebar, canvas, detailsPanel]);
    }
  }, [collapseSidebar]);

  return (
    <Stack height="100%" width="100%">
      <SingleSourceModelWrapper>
        <SingleSourceFilters />
        <Allotment
          separator
          defaultSizes={defaultSizes}
          proportionalLayout={true}
          vertical={false}
          ref={ref}
          onDragEnd={onDragEnd}
        >
          <Allotment.Pane minSize={collapseSidebar ? 64 : 300} maxSize={collapseSidebar ? 64 : 1000}>
            <Sidebar
              assetExplorer={assetExplorer}
              explorer={explorer}
              profile={user}
              collapsed={collapseSidebar}
              onCollapse={onCollapseSidebar}
            />
          </Allotment.Pane>
          <Allotment.Pane>
            <SingleSourceModel />
          </Allotment.Pane>
          <Allotment.Pane visible={Boolean(outlet)} minSize={200} className={'!overflow-visible'}>
            {outlet ? (
              <Box height="100%" width="100%">
                {outlet}
              </Box>
            ) : null}
          </Allotment.Pane>
        </Allotment>
      </SingleSourceModelWrapper>
    </Stack>
  );
};
