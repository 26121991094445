import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { AppRegistry } from '../../apps/AppRegistry';
import { Scheme, Construct, Asset, Script } from 'narrative-studio-sdk';

interface CreateSchemeParams extends IParams {
  scheme: Scheme;
}

export class CreateSchemeCommand extends CommandBase<CreateSchemeParams> {
  execute(params: CreateSchemeParams): EventBase | CommandError | undefined {
    const entities: { constructs: Construct[]; assets: Asset[]; scripts: Script[] } = {
      constructs: [],
      assets: [],
      scripts: [],
    };
    params.scheme.categories.forEach((category) => {
      category.constructs.forEach((construct) => {
        entities.constructs.push(construct);
      });
      category.assets.forEach((asset) => {
        entities.assets.push(asset);
      });
    });
    this.model.toolbar.addSchemes([params.scheme]);
    AppRegistry.registerEntities(entities);
    return;
  }
}
