import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';

export class Process extends ConstructBase {
  style = { backgroundColor: '#C6A2D2', textColor: '#000000' };

  get type(): EntityType {
    return EntityType.Process;
  }
}
