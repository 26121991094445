import * as Y from 'yjs';
import { Transaction, YEvent, YMapEvent } from 'yjs';
import { sid } from '@xspecs/short-id';
import { logger } from '@xspecs/logger';
import { SingleSourceModel } from '../SingleSourceModel';
import { isTransient } from './Transient';
import { isLocal } from './LocalStoreProvider';
import { EntityVersionUpcastRegistry } from './EntityVersionUpcastRegistry';
import { EntityUpcaster } from './EntityUpcaster';
import { SerializedEntity, SerializedEntityChanges } from '../types';
import { NotificationTypes } from '../observable/SingleSourceObserver';
import { RecalculateDimensionsCommand } from '../commands/dimensions/RecalculateDimensionsCommand';
import { EntityType } from '../entities/EntityType';
import { ToggleScriptVisibilityCommand } from '../commands/scripts/ToggleScriptVisibilityCommand';
import { SchemeSetEvent } from '../commands/apps/SetSchemeCommand';

const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';

const SCOPE_TOKEN = '__SCOPE__';

export enum TransactionType {
  Local = 'local',
  Remote = 'remote',
}

export class SingleSourceModelYjsAdaptor {
  private readonly localChangeTracker: { [key: string]: string[] } = {};
  private readonly userActionOrigin: string = sid();
  private readonly initialLoadCallback: () => void = () => {};
  private undoManager: Y.UndoManager;
  private entityVersionRegistry: EntityVersionUpcastRegistry;
  private disabled: boolean = false;
  private isE2EMode = typeof window !== 'undefined' ? localStorage.getItem('E2E_MODE') === 'true' : false;

  private constructor(
    private readonly model: SingleSourceModel,
    private readonly storageMap: Y.Map<any>,
    private readonly versionMap: Y.Map<any>,
    private readonly settingsMap: Y.Map<any>,
    private readonly fileTreeMap: Y.Map<any>,
    initialLoadCallback?: () => void,
  ) {
    if (initialLoadCallback) this.initialLoadCallback = initialLoadCallback;
    this.initialize();
  }

  static instance: SingleSourceModelYjsAdaptor | null = null;

  public static getInstance(
    model: SingleSourceModel,
    storageMap: Y.Map<any>,
    versionMap: Y.Map<any>,
    settingsMap: Y.Map<any>,
    fileTreeMap: Y.Map<any>,
    initialLoadCallback?: () => void,
  ): SingleSourceModelYjsAdaptor {
    if (!this.instance) {
      this.instance = new SingleSourceModelYjsAdaptor(
        model,
        storageMap,
        versionMap,
        settingsMap,
        fileTreeMap,
        initialLoadCallback,
      );
      if (isBrowser) {
        window['model'] = this.instance.model;
        window['entities'] = this.instance.model.entities;
        window['adaptor'] = this.instance;
        window['undo'] = this.instance.undo.bind(this.instance);
      }
    }
    return this.instance;
  }

  public undo() {
    this.undoManager.undo();
  }

  public redo() {
    this.undoManager.redo();
  }

  // private onUndoManagerStackItemPopped(stackItemEvent: { stackItem?: any; origin?: any; type?: "undo" | "redo"; changedParentTypes: any; }): void {
  // console.log('SingleSourceModelYjsAdaptor.onUndoManagerStackItemPopped called', stackItemEvent);
  // stackItemEvent.changedParentTypes.forEach((events: YEvent<any>[]) => {
  //   events.forEach((event) => {
  //     if (event instanceof YMapEvent) {
  //       event.keysChanged.forEach((key) => {
  //         console.log('SingleSourceModelYjsAdaptor.onUndoManagerStackItemPopped changed key', key);
  //         //this.model.entityRepository.updateParentChildren()
  //         const entity = this.model.entityRepository.get(key);
  //         if(entity) {
  //           console.log('SingleSourceModelYjsAdaptor.onUndoManagerStackItemPopped changed entity', entity);
  //           this.model.entityRepository.updateParentChildren(entity);
  //         }
  //       });
  //     }
  //   });
  // });
  //}

  private initialize() {
    this.model.messageBus.subscribe([SchemeSetEvent], (event: SchemeSetEvent) => {
      this.saveSetting(`${event.params.spaceId}_scheme`, event.params.scheme);
    });
    // this.model.messageBus.subscribe([FileTreeChangedEvent], (event: FileTreeChangedEvent) => {
    //   this.storageMap.doc!.transact(() => {
    //     event.params.changes.forEach((change) => {
    //       this.fileTreeMap.set(change.id, new Y.Map(this.getFields(change)));
    //     });
    //     event.params.deleted.forEach((deleted) => {
    //       this.fileTreeMap.delete(deleted.id);
    //     });
    //   }, TransactionType.Local);
    // });

    // ToDo: move this out to composite root
    const entityVersionUpcastRegistry = new EntityVersionUpcastRegistry(
      new EntityUpcaster(),
      this.storageMap,
      this.model,
    );
    entityVersionUpcastRegistry.register();
    this.entityVersionRegistry = entityVersionUpcastRegistry;

    this.undoManager = new Y.UndoManager([this.storageMap], {
      trackedOrigins: new Set([this.userActionOrigin]),
    });
    let initialLoadHandled = false;
    // let callCount = 0;
    this.storageMap.observeDeep((events, transaction) => {
      this.onStorageMapChange(events, transaction);
      // console.log('SingleSourceModeYjsAdaptor.onStorageMapChange called', callCount++);
      if (!initialLoadHandled) {
        initialLoadHandled = true;
        this.model.isLoaded = true;
        if (this.initialLoadCallback) {
          this.initialLoadCallback();
        }
      }
    });
    // this.fileTreeMap.observeDeep((events, transaction) => {
    //   this.onFileTreeMapChange(events, transaction);
    // });
    // clear old items from transient map
    this.model.observer.subscribe(NotificationTypes.OnSave, this.onSave.bind(this));
    this.model.observer.subscribe(NotificationTypes.OnUndo, () => this.undo());
    this.model.observer.subscribe(NotificationTypes.OnRedo, () => this.redo());

    //this.undoManager.on('stack-item-popped', (event) => this.onUndoManagerStackItemPopped(event));
  }

  private arraysAreEqual(arr1: string[], arr2: string[]): boolean {
    return arr1.length === arr2.length && [...arr1].sort().every((value, index) => value === [...arr2].sort()[index]);
  }

  private onStorageMapChange(events: YEvent<any>[], transaction: Transaction): void {
    if (this.disabled) return;
    const changes: SerializedEntityChanges = { added: [], updated: [], deleted: [] };
    const updatesMap = {};
    events.forEach((event: YMapEvent<any>) => {
      event.changes.keys.forEach((change, key) => {
        if (change.action === 'delete') {
          // there isn't a nice way to get the deleted item so had to use this as size of the YMap is 0 even though it contains the data!!
          let deletedItemType = change?.oldValue?._map?.get('type')?.content['arr'];
          let deletedItemScope = change?.oldValue?._map?.get('scopes')?.content['arr'];
          if (Array.isArray(deletedItemType)) deletedItemType = deletedItemType[0];
          if (Array.isArray(deletedItemScope)) deletedItemScope = deletedItemScope[0];
          changes.deleted.push({ id: key, type: deletedItemType, scopes: deletedItemScope });
        } else {
          const entity = this.storageMap.get(key)?.toJSON?.() ?? this.storageMap.get(key);
          //entity = this.upcastToLatestVersion(entity, event, key);
          const id = event.target.get('id');
          if (change.action === 'add') this.applyAddButMaybeUpdate(id, updatesMap, key, changes, event, entity);
          else if (change.action === 'update') this.applyUpdateChange(id, updatesMap, key, event);
        }
      });
    });
    changes.updated = Object.values(updatesMap);
    if (this.entityVersionRegistry.entityUpcaster.hasAfterUpcastProcessing()) {
      this.entityVersionRegistry.entityUpcaster.afterUpcastsProcessing(this.storageMap);
    }
    this.applyMapChanges(transaction.origin, changes);
    this.entityVersionRegistry.entityUpcaster.afterModelLoaded();
  }

  private onFileTreeMapChange(events: YEvent<any>[], transaction: Transaction): void {
    if (this.disabled) return;
    if (transaction.origin === TransactionType.Local) return;
    events.forEach((event: YMapEvent<any>) => {
      event.changes.keys.forEach((change, key) => {
        const entity: {
          fileName: string;
          fileType: string;
          entityId: string;
          text: string;
          id: string;
          parentId: string;
          content: string;
        } = this.fileTreeMap.get(key)?.toJSON?.() ?? this.fileTreeMap.get(key);
        this.model.fileTree.applyChange(entity);
      });
    });
  }

  private applyAddButMaybeUpdate(
    id: string,
    updatesMap: any,
    key: string,
    changes: SerializedEntityChanges,
    event: YMapEvent<any>,
    entity: SerializedEntity,
  ): void {
    if (id) {
      // this means a field was added - which is an update to an entity! WTF!
      this.applyUpdateChange(id, updatesMap, key, event);
    } else {
      if (entity && Object.keys(entity).length > 0) {
        if (this.entityVersionRegistry.entityUpcaster.isRetiredEntity(entity)) {
          //logger.error('Item to upcast is retired', JSON.stringify(entity, null, 2));
          return;
        }
        if (this.entityVersionRegistry.entityUpcaster.hasUpcaster(entity)) {
          this.disabled = true;
          this.removeNonWorkspaceEntitiesOnce();
          const upcastEntities = this.entityVersionRegistry.entityUpcaster.upcast(entity)!;
          upcastEntities.forEach((upcastEntity) => {
            if (upcastEntity && Object.keys(upcastEntity).length > 0) {
              const existingEntity = changes.added.find((addedEntity) => addedEntity.id === upcastEntity.id);
              if (existingEntity) {
                changes.added = changes.added.map((addedEntity) =>
                  addedEntity.id === upcastEntity.id ? upcastEntity : addedEntity,
                );
              } else {
                changes.added.push(upcastEntity);
              }
              this.storageMap.doc!.transact(() => {
                this.storageMap.set(upcastEntity.id, new Y.Map(this.getFields(upcastEntity)));
              }, TransactionType.Remote);
            }
          });
          this.disabled = false;
        } else {
          changes.added.push(entity);
        }
      } else {
        logger.error('Item to update not found in storage map or is empty', JSON.stringify(entity, null, 2));
        this.storageMap.delete(id);
      }
    }
  }

  private applyUpdateChange(id: string, map: any, key: string, event: YMapEvent<any>) {
    this.initializeMap(id, map, key);
    if (id) map[id][key] = this.getValue(event, key); // local changes
    else map[key] = this.getValue(event, key); // remote changes
  }

  // private applyUpdateChange(id: string, map: any, key: string, entity: any) {
  //   this.initializeMap(id, map, key);
  //   if (id) map[id][key] = this.getValueFromEntity(entity, key); // local changes
  //   else map[key] = this.getValueFromEntity(entity, key); // remote changes
  // }

  private getValueFromEntity(entity: any, key: string) {
    return entity[key];
  }

  private getValue(event: YMapEvent<any>, key: string) {
    // TODO Rami is this going to break shit?!
    return event.target.get(key)?.toJSON?.() ?? event.target.get(key);
  }

  private initializeMap(id: string, updatesMap: any, key: string) {
    if (!updatesMap[id ?? key]) updatesMap[id ?? key] = {};
  }

  private applyMapChanges(transactionOrigin: string, changes: SerializedEntityChanges) {
    const mapChanges = [...changes.added, ...changes.updated, ...changes.deleted]
      .filter((item) => item)
      .map((item) => item.id);
    const jsonChanges = JSON.parse(JSON.stringify(changes));
    if (this.isLocalMapChange(transactionOrigin, mapChanges)) {
      if (!this.model.validateChanges(jsonChanges)) {
        logger.error('SingleSourceModelYjsAdaptor:applyMapChanges: Rolling back changes');
        setTimeout(() => this.undo(), 250);
        this.undo();
      }
      delete this.localChangeTracker[transactionOrigin];
    } else {
      this.model.entities.applyRemoteChanges(jsonChanges);
    }
  }

  private isLocalMapChange(transactionOrigin: string, mapChanges: any[]) {
    if (transactionOrigin === TransactionType.Local) {
      return true;
    }
    return (
      this.localChangeTracker[transactionOrigin] &&
      transactionOrigin &&
      this.arraysAreEqual(mapChanges, this.localChangeTracker[transactionOrigin])
    );
  }

  private onSave(changes: { added: any[]; updated: any[]; deleted: any[] }): void {
    this.localChangeTracker[this.userActionOrigin] = Object.values(changes).flatMap((change) =>
      change.map((entity) => entity.id),
    );
    try {
      this.storageMap.doc!.transact(() => {
        changes.added.forEach((entity) => {
          if (entity && Object.keys(entity).length > 0) {
            this.storageMap.set(entity.id, new Y.Map(this.getFields(entity)));
          }
        });
        // changes.updated.forEach((entity) => {
        //   const entityMap = this.storageMap.get(entity.id);
        //   this.getFields(entity).forEach(([k, v]) => entityMap.set(k, v));
        //   for (const key of entityMap.keys())
        //     if (entity[key] === null || entity[key] === undefined) entityMap.delete(key);
        // });
        changes.updated.forEach((entity) => {
          if (entity && Object.keys(entity).length > 0) {
            const newEntityMap = new Y.Map(this.getFields(entity));
            this.storageMap.set(entity.id, newEntityMap);
            for (const key of newEntityMap.keys())
              if (entity[key] === null || entity[key] === undefined) newEntityMap.delete(key);
          }
        });
        changes.deleted.forEach(({ id }) => {
          const storedEntity = this.storageMap.has(id);
          if (storedEntity) {
            this.storageMap.delete(id);
          } else {
            logger.error('SingleSourceModelYjsAdapter: entity not found while updating map', id);
          }
        });
      }, this.userActionOrigin);
      //this.model.observer.notify(NotificationTypes.OnAfterSave, changes);
    } catch (e) {
      this.renderTest(changes);
      logger.error(e);
    }
  }

  public serialize(raw = false): string {
    if (raw) return this.getDeScopedData();
    return `adaptor.bootstrap(\`{` + `  "storage": ${this.getDeScopedData()}` + `}\`);`;
  }

  private getDeScopedData() {
    return this.getJsonForYMap(this.storageMap).replaceAll(this.getScope(), SCOPE_TOKEN);
  }

  public reset(sure = false) {
    if (isBrowser && !window.location.host.includes('localhost') && !sure && !this.isE2EMode) {
      logger.log('Data for the WHOLE ORGANIZATION will be cleared IN PRODUCTION. If you are sure, call reset(true)');
      return;
    }
    if (isBrowser && !window.location.host.includes('localhost') && sure) {
      if (!confirm("Are you sure sure you want to reset this ENTIRE ORGANIZATION's data?")) return;
    }
    this.storageMap.clear();
    this.model.boundariesIndex.clear();
    this.model.entityRepository.list().forEach((entity) => this.model.entityRepository.delete(entity.id));
    this.model.cache.clear();
    this.model.graph.clear();
  }

  public bootstrap(data: string, sure = false, direct = false) {
    if (isBrowser && !window.location.host.includes('localhost') && !sure) {
      logger.log('Bootstrap data will be ignored in production. If you are sure, call bootstrap with sure=true');
      return;
    }
    if (isBrowser && !window.location.host.includes('localhost') && sure) {
      if (!confirm('Are you sure sure you want to load this data?')) return;
    }
    const map = JSON.parse(data.replaceAll(SCOPE_TOKEN, this.getScope()));
    map.storage = map.storage.filter((entity) => {
      return entity?.scopes?.includes(this.getScope()) || entity?.scopes?.includes('*');
    });
    const storageChanges = {
      added: map.storage,
      updated: [],
      deleted: [],
    };
    this.clearCurrentScope();

    if (direct) {
      this.onSave(storageChanges);
      return;
    }

    this.disabled = true;
    this.model.observer.disable();
    this.onSave(storageChanges);
    this.disabled = false;
    this.model.observer.enable();
    if (isBrowser) window.location.reload();
  }

  // test(reload: boolean) {
  //   this.reset();
  //   setTimeout(() => {
  //     this.bootstrap(skeleton);
  //   }, 100);
  //   if (reload)
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 100);
  // }

  public replaceData(data: string) {
    const map = JSON.parse(data.replaceAll(SCOPE_TOKEN, this.getScope()));
    this.storageMap.doc!.transact(() => {
      this.clearCurrentScope();
      map.storage.forEach((entity) => {
        if (entity && Object.keys(entity).length > 0) {
          this.storageMap.set(entity.id, new Y.Map(this.getFields(entity)));
        }
      });
    }, TransactionType.Remote);
  }

  private clearCurrentScope() {
    this.model.entityRepository.list().forEach((entity) => this.storageMap.delete(entity.id));
  }

  public listVersions(maxCount: number = 100): { version: string; date: string; user: string; scopes: string[] }[] {
    const currentScope = this.getScope();
    const versions: {
      version: string;
      date: string;
      user: string;
      scopes: string[];
    }[] = [];
    this.versionMap.forEach((value, version) => {
      if (value?.scopes?.includes(currentScope)) {
        versions.push({ version, date: value.date, user: value.user, scopes: value.scopes });
      }
    });
    versions.sort(
      (a: { date: string }, b: { date: string }) => new Date(b.date).getTime() - new Date(a.date).getTime(),
    );
    return versions.slice(0, maxCount);
  }

  public restoreVersion(version: string, versionStorageMap: Y.Map<any>) {
    const currentScope = this.getScope();
    // const versionData = this.versionMap.get(version);
    // if (!versionData) {
    //   logger.error('Version not found:', version);
    //   return;
    // }
    this.storageMap.doc!.transact(() => {
      this.storageMap.forEach((value, key) => {
        if (value.get('scopes') && value.get('scopes').includes(currentScope)) {
          this.storageMap.delete(key);
        }
      });
      versionStorageMap.forEach((value, key) => {
        if (value.get('scopes') && value.get('scopes').includes(currentScope)) {
          const newMap = new Y.Map();
          value.forEach((subValue, subKey) => {
            newMap.set(subKey, subValue);
          });
          this.storageMap.set(key, newMap);
        }
      });
    }, this.userActionOrigin);
  }

  public saveSetting(key: string, value: any): void {
    this.settingsMap.doc?.transact(() => {
      this.settingsMap.set(key, value);
    }, TransactionType.Local);
  }

  public getSetting(key: string): any {
    return this.settingsMap.get(key);
  }

  private getScope(): string {
    return this.model['entityRepository']['scopes'][0];
  }

  private getJsonForYMap(map) {
    return JSON.stringify(Object.values(JSON.parse(JSON.stringify(map.toJSON()))));
  }

  private getFields(obj: any): [string, any][] {
    return JSON.parse(
      JSON.stringify(Object.entries(obj).filter(([key]) => !isTransient(obj, key) && !isLocal(obj, key))),
    );
  }

  private renderTest(changes: { added: any[]; updated: any[]; deleted: any[] }) {
    try {
      const test = `it.only('should replicate the bug', async () => {
    const data = JSON.stringify(${this.getDeScopedData()});
    adaptor['updateMap']({
      added: JSON.parse(data.replaceAll('${SCOPE_TOKEN}', SCOPE)),
      updated: [],
      deleted: [],
    });
    
    adaptor['updateMap'](${JSON.stringify(changes)});
  });`;
      logger.log(test);
    } catch (e) {
      logger.error(`couldn't render a test for scope, entities, changes`, changes);
      logger.error(e);
    }
  }

  dispose() {
    SingleSourceModelYjsAdaptor.instance = null;
  }

  public fixPositions() {
    const entityRepository = this.model.entityRepository;
    const entityIds = entityRepository.list().map((entity) => entity.id);
    this.model.messageBus.sendInternal(RecalculateDimensionsCommand, { entityIds });
    entityRepository
      .list()
      .filter((e) => e.type === EntityType.Action)
      .map((e) => e.id)
      .forEach((entityId) => {
        this.model.messageBus.sendInternal(ToggleScriptVisibilityCommand, { entityId });
        this.model.messageBus.sendInternal(ToggleScriptVisibilityCommand, { entityId });
      });
    entityRepository.save();
  }

  private removeNonWorkspaceEntitiesOnceHasRun = false;
  private readonly removeNonWorkspaceEntities: string[] = [];

  private removeNonWorkspaceEntitiesOnce() {
    if (this.removeNonWorkspaceEntitiesOnceHasRun) return;
    this.removeNonWorkspaceEntitiesOnceHasRun = true;
    this.model.observer.disable();
    if (typeof window !== 'undefined') {
      const scopes = JSON.parse(window.localStorage.getItem('workspaces') || '[]');
      scopes.push('*'); // for labels
      logger.log('Cleaning up not-in-scope entities', scopes);
      this.storageMap.doc!.transact(() => {
        this.storageMap.forEach((entityValue, entityKey) => {
          this.removeNonWorkspaceEntities.push(entityKey);
          const entityScopes = entityValue.get('scopes');
          if (!entityScopes || !scopes.includes(entityScopes[0])) this.storageMap.delete(entityKey);
        });
      });
      this.model.observer.enable();
    }
  }
}
