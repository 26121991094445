import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { Edge, strToHandleLocation } from '../../entities/transitions/Edge';
import { CommandError } from '../../ErrorStore';
import { EntityBase } from '../../entities/EntityBase';
import { Attachment } from '../../entities/assets/Attachment';
import { ConstructBase } from '../../entities/constructs/ConstructBase';

interface UpdateRelationshipParams extends IParams {
  id: string;
  sourceId: string;
  targetId: string;
  sourceHandle?: string;
  targetHandle?: string;
}

interface RelationshipUpdatedParams extends IParams {
  id: string;
  sourceId: string;
  targetId: string;
  sourceHandle?: string;
  targetHandle?: string;
}

export class RelationshipUpdatedEvent extends EventBase {
  static eventType = 'RelationshipUpdatedEvent';

  constructor(public readonly params: RelationshipUpdatedParams, public readonly source = UpdateRelationshipCommand) {
    super();
  }
}

export class UpdateRelationshipCommand extends CommandBase<UpdateRelationshipParams> {
  execute(params: UpdateRelationshipParams): RelationshipUpdatedEvent | CommandError {
    const { id, sourceId, targetId, sourceHandle, targetHandle } = params;

    const edge = this.model.entityRepository.get<Edge>(id);
    if (!edge) return CommandError.of(new Error('Edge not found'), 'error');

    const newSource = this.model.entityRepository.get<EntityBase>(sourceId);
    if (!newSource) return CommandError.of(new Error('Source entity not found'), 'error');

    const newTarget = this.model.entityRepository.get<EntityBase>(targetId);
    if (!newTarget) return CommandError.of(new Error('Target entity not found'), 'error');

    const sourceHandleLocation = sourceHandle ? strToHandleLocation(sourceHandle) : null;
    if (sourceHandleLocation instanceof Error) return CommandError.of(sourceHandleLocation, 'error');

    const targetHandleLocation = targetHandle ? strToHandleLocation(targetHandle) : null;
    if (targetHandleLocation instanceof Error) return CommandError.of(targetHandleLocation, 'error');

    if (newSource instanceof ConstructBase && newSource.script && newTarget != newSource.script) {
      return CommandError.of(new Error('The target of this edge can only be the script'), 'error');
    }

    if (newSource instanceof Attachment && newSource.preview && newTarget != newSource.preview) {
      return CommandError.of(new Error('The target of this edge can only be the preview'), 'error');
    }

    edge.source = newSource;
    edge.target = newTarget;
    edge.sourceHandleLocation = sourceHandleLocation || edge.sourceHandleLocation;
    edge.targetHandleLocation = targetHandleLocation || edge.targetHandleLocation;

    this.model.entityRepository.update(edge);

    return new RelationshipUpdatedEvent({
      ...params,
    });
  }
}
