import { Box, Button, IconButton, InputAdornment, OutlinedInput, Stack, SxProps, Theme } from '@mui/material';
import { AutoAwesomeOutlined, Search, SwapVert, Close } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import {
  arrow,
  autoPlacement,
  FloatingArrow,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { useApplication } from '../../../wrappers/application-context/application-context';
import { AssetSort, ExplorerSort } from '@xspecs/single-source-model';
import { SidebarTabValues } from '../sidebar-tabs/sidebar-tabs';

export type ControlsProps = {
  searchValue: string;
  sortValue: AssetSort | ExplorerSort;
  sortOptions: typeof AssetSort | typeof ExplorerSort;
  type: SidebarTabValues;
};

export const Controls = (props: ControlsProps) => {
  const { searchValue, sortValue, sortOptions, type } = props;
  const [showFloating, setShowFloating] = useState(false);

  const { formatMessage: f } = useIntl();

  const arrowRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { application } = useApplication();

  const { floatingStyles, refs, context } = useFloating({
    placement: 'bottom-end',
    middleware: [
      offset(10),
      autoPlacement({ allowedPlacements: ['bottom-end'] }),
      arrow({ element: arrowRef.current }),
    ],
    open: showFloating,
    onOpenChange: setShowFloating,
  });
  const click = useClick(context, { keyboardHandlers: false });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  const setSortValue = useCallback(
    (sortValue: AssetSort | ExplorerSort) => {
      if (type === SidebarTabValues.Explorer) {
        application?.model.sidebarInteractor.setExplorerSort(sortValue as ExplorerSort);
      } else {
        application?.model.sidebarInteractor.setAssetSort(sortValue as AssetSort);
      }
      setShowFloating(false);
    },
    [application?.model.sidebarInteractor, type],
  );

  const setSearchValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (type === SidebarTabValues.Explorer) {
        application?.model.sidebarInteractor.setExplorerSearch(e.target.value);
      } else {
        application?.model.sidebarInteractor.setAssetSearch(e.target.value);
      }
    },
    [application?.model.sidebarInteractor, type],
  );

  const sortOptionsList = useMemo(() => {
    return [
      {
        label: f({ id: 'sort-newest' }),
        value: type === SidebarTabValues.Explorer ? ExplorerSort.Newest : AssetSort.Newest,
      },
      {
        label: f({ id: 'sort-a-z' }),
        value: type === SidebarTabValues.Explorer ? ExplorerSort.Ascending : AssetSort.Ascending,
      },
      {
        label: f({ id: 'sort-z-a' }),
        value: type === SidebarTabValues.Explorer ? ExplorerSort.Descending : AssetSort.Descending,
      },
    ];
  }, [f, type]);

  return (
    <Box sx={controlsSx}>
      <OutlinedInput
        size="small"
        placeholder="Search"
        fullWidth
        startAdornment={
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }
        disabled={type === SidebarTabValues.Files}
        endAdornment={
          searchValue ? (
            <InputAdornment
              position="end"
              sx={{ cursor: 'pointer' }}
              onClick={() => setSearchValue({ target: { value: '' } } as ChangeEvent<HTMLInputElement>)}
            >
              <Close />
            </InputAdornment>
          ) : null
        }
        inputRef={inputRef}
        value={searchValue || ''}
        onChange={setSearchValue}
      />
      <Box ref={refs.setReference} {...getReferenceProps()}>
        <IconButton size="small">
          <SwapVert color="action" />
        </IconButton>
      </Box>
      {showFloating ? (
        <Box ref={refs.setFloating} {...getFloatingProps()} style={floatingStyles} sx={floatingSx}>
          <FloatingArrow width={20} height={12} ref={arrowRef} context={context} fill="white" />
          <Stack gap={0.25} alignItems="start">
            {sortOptionsList.map((option) => (
              <Button
                key={option.label}
                sx={{
                  ...sortItemSx,
                  backgroundColor: sortValue === option.value ? 'action.selected' : 'action',
                }}
                startIcon={<AutoAwesomeOutlined sx={{ width: '16px', color: 'action.active' }} />}
                onClick={() => setSortValue(option.value)}
                disableRipple
                disabled={type === SidebarTabValues.Files}
              >
                {option.label}
              </Button>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

const controlsSx = { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 };

const floatingSx: SxProps<Theme> = (theme) => ({
  padding: 0.5,
  borderRadius: 1,
  width: '200px',
  background: theme.palette.background.paper,
  boxShadow:
    '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)',
  zIndex: 'calc(infinity)',
});

const sortItemSx: SxProps<Theme> = {
  color: 'text.primary',
  width: '100%',
  textAlign: 'left',
  justifyContent: 'start',
  textTransform: 'none',
};
