import { sid } from '@xspecs/short-id';
import { logger } from '@xspecs/logger';
import { Position, SerializedEntity } from '../types';
import { IClipboard } from './IClipboard';
import { Edge } from '../entities/transitions/Edge';
import { EntitySelectionTracker } from '../data/EntitySelectionTracker';
import { DEBUG_CONFIG } from '../debug-config';
import { EntityType } from '../entities/EntityType';
import { ConstructBase } from '../entities/constructs/ConstructBase';
import { EntityBase } from '../entities/EntityBase';
import { AssetTypes } from '../entities/assets/AssetTypes';
import { Attachment } from '../entities/assets/Attachment';
import { EntityRepository } from '../data/EntityRepository';
import { Preview } from '../entities/assets/Preview';
import { MessageBus } from '../commands/framework/MessageBus';
import { DeleteEntitiesCommand } from '../commands/entities/DeleteEntitiesCommand';
import { ScriptBase } from '../entities/scripts/ScriptBase';

export class Clipboard {
  constructor(
    private readonly clipboard: IClipboard,
    private readonly entityRepository: EntityRepository,
    private readonly entitySelectionTracker: EntitySelectionTracker,
    private messageBus: MessageBus,
  ) {}

  public async cut(): Promise<void> {
    const serializedEntities = this.getSelectedSerializedEntities();
    if (!serializedEntities) return;
    const entityIds = serializedEntities.map((p) => p.id);
    this.messageBus.send(DeleteEntitiesCommand, { entityIds: entityIds });
    await this.clipboard.copy(JSON.stringify(serializedEntities));
  }

  public async copy(): Promise<void> {
    const serializedEntities = this.getSelectedSerializedEntities();
    if (serializedEntities.length === 0) return;
    await this.clipboard.copy(JSON.stringify(serializedEntities));
  }

  public async copyRaw(data: object): Promise<void> {
    await this.clipboard.copy(JSON.stringify(data));
  }

  public async read<T = SerializedEntity[]>(): Promise<T> {
    const text = await this.clipboard.read();
    if (!text) return [] as T;
    try {
      return JSON.parse(text);
    } catch (e) {
      return [] as T;
    }
  }

  public async paste(position: Position): Promise<SerializedEntity[]> {
    const clipboardEntities = await this.read<SerializedEntity[]>();
    return this.pasteSerializedEntities(clipboardEntities, position) ?? [];
  }

  public duplicate(position: Position): SerializedEntity[] {
    const clipboardEntities = this.getSelectedSerializedEntities();
    return this.pasteSerializedEntities(clipboardEntities, position);
  }

  private pasteSerializedEntities(clipboardEntities: SerializedEntity[], position: Position): SerializedEntity[] {
    if (clipboardEntities.length === 0) return [];
    const oldToNewIdMap = new Map<string, string>();
    const validEntities = clipboardEntities.filter(
      (t) => !AssetTypes.isAsset(t.type as EntityType) && t.type != EntityType.Label && t.type !== EntityType.Edge,
    );
    const minX = Math.min(...validEntities.map((target) => target.position.x));
    const minY = Math.min(...validEntities.map((target) => target.position.y));

    // Step 1: sort the entities so that narratives and actions are processed first
    clipboardEntities.sort((a, b) => {
      const typeOrder = ['Narrative', 'Action'];
      const aPriority = typeOrder.includes(a.type) ? 0 : 1;
      const bPriority = typeOrder.includes(b.type) ? 0 : 1;
      if (aPriority === bPriority) {
        return 0;
      }
      return aPriority - bPriority;
    });

    // Step 2: Replace IDs in clipboardEntities with new IDs where applicable and update positions
    clipboardEntities.forEach((entity) => {
      const existingEntity = this.getEntity(entity.id);
      if (existingEntity) {
        // If the entity already exists in the model, generate a new ID and update the ID mapping
        let newId = sid();
        if (existingEntity instanceof ScriptBase) {
          // the id in this case should be made of the newly generated Action id followed by _script
          const existingActionId = oldToNewIdMap.get(existingEntity.parent!.id);
          if (existingActionId) {
            newId = existingActionId + '_script';
          }
        }
        oldToNewIdMap.set(existingEntity.id, newId);
        oldToNewIdMap.set(newId, newId);
        entity.id = newId;
        // add assets to the id map
        if (existingEntity instanceof ConstructBase && existingEntity.attachments.length > 0) {
          existingEntity.attachments.map((attachment) => {
            if (attachment.asset) {
              oldToNewIdMap.set(attachment.asset.id, attachment.asset.id);
            }
          });
        } else if (existingEntity instanceof Attachment && existingEntity.asset) {
          oldToNewIdMap.set(existingEntity.asset.id, existingEntity.asset.id);
        } else if (existingEntity.labels.length > 0) {
          existingEntity.labels.map((label) => {
            oldToNewIdMap.set(label.id, label.id);
          });
        }
      } else {
        // If the entity doesn't exist in the model, this is a cut so just map the existing ID to itself
        oldToNewIdMap.set(entity.id, entity.id);
        // also map the assets for the attahcments
        if (entity.type === EntityType.Attachment && entity.asset) {
          oldToNewIdMap.set(entity.asset.$ref, entity.asset.$ref);
        }
      }
      if (entity.type !== EntityType.Label && !AssetTypes.isAsset(entity.type as EntityType)) {
        entity.position = this.getNewPosition({
          node: entity,
          pasteX: position.x,
          pasteY: position.y,
          minX: minX,
          minY: minY,
        });
      }
    });

    // Step 3: Update $ref fields with new IDs
    clipboardEntities.forEach((entity) => {
      // gql entities need to maintain parents
      if (entity.type === EntityType.GqlField) {
        return;
      }
      this.updateRefs(entity, oldToNewIdMap);
      entity.scopes = this.currentScopes;
    });

    // step 4 remove any edges that have no source or target
    clipboardEntities = clipboardEntities.filter((entity) => {
      return !(entity.type === EntityType.Edge && (!entity.source || !entity.target));
    });

    // Step 5: Apply the changes to the model and persist
    this.applyLocalChanges({ added: clipboardEntities, updated: [], deleted: [] });
    return clipboardEntities;
  }

  private applyLocalChanges(serializedChanges: { added: any[]; updated: any[]; deleted: any[] }): boolean {
    if (!this.entityRepository.validateChanges(serializedChanges)) return false;
    this.entityRepository.applyLocalChanges(serializedChanges);
    this.entityRepository.save();
    return true;
  }

  private updateRefs(obj: any, idMap: Map<string, string>) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'object' && obj[key] !== null) {
        if ('$ref' in obj[key]) {
          const ref = idMap.get(obj[key]['$ref']);
          if (!ref) {
            // it's not in the keep the value if exits in the idmap values
          }
          // if ref is undefined, it means the entity is not in the clipboard so remove reference and set value to undefined
          // if obj[key] is an array, remove the reference from the array
          if (!ref) {
            if (Array.isArray(obj)) {
              obj.splice(obj.indexOf(obj[key]), 1);
            } else {
              obj[key] = undefined;
            }
          } else obj[key]['$ref'] = ref;
        } else {
          this.updateRefs(obj[key], idMap);
        }
      }
    }
  }

  public getSelectedSerializedEntities(): SerializedEntity[] {
    const selectedEntities = Object.keys(this.entitySelectionTracker.localSelections).map((key) => this.getEntity(key));
    if (DEBUG_CONFIG.clipboard) logger.log('Clipboard selectedEntities', selectedEntities);
    const selectedEntitySet = new Set(selectedEntities);
    this.addChildrenEntitiesToSet(selectedEntitySet, selectedEntities, (entity: EntityBase) =>
      this.isConstructWithScript(entity),
    );
    this.addChildrenEntitiesToSet(
      selectedEntitySet,
      selectedEntities,
      (entity: EntityBase) => entity instanceof ScriptBase,
    );
    // this.addChildrenEntitiesToSet(
    //   selectedEntitySet,
    //   selectedEntities,
    //   (entity: EntityBase) => entity instanceof Action,
    // );
    // this is needed when we have a construct script within an another, i.e. Action in a Narrative that has entities inside
    this.addChildrenEntitiesToSet(selectedEntitySet, selectedEntities, (entity: EntityBase) =>
      this.isConstructWithScript(entity),
    );
    this.addChildrenEntitiesToSet(
      selectedEntitySet,
      selectedEntities,
      (entity: EntityBase) => entity instanceof ScriptBase,
    );
    this.addChildrenEntitiesToSet(
      selectedEntitySet,
      selectedEntities,
      (entity: EntityBase) => entity instanceof ConstructBase,
    );
    this.addChildrenEntitiesToSet(
      selectedEntitySet,
      selectedEntities,
      (entity: EntityBase) => entity instanceof Preview,
    );
    this.addChildrenEntitiesToSet(
      selectedEntitySet,
      selectedEntities,
      (entity: EntityBase) => entity instanceof Attachment,
    );
    this.entityRepository
      .list()
      .filter((p) => p instanceof Edge)
      .forEach((edge: Edge) => {
        if (selectedEntitySet.has(edge.source) || selectedEntitySet.has(edge.target)) {
          selectedEntitySet.add(edge);
        }
      });
    if (selectedEntities.length === 0) return [];
    return Array.from(selectedEntitySet).map((entity: any) => entity.serialize() as SerializedEntity);
  }

  private addChildrenEntitiesToSet(
    entitySet: Set<any>,
    entities: any[],
    entityPredicate: (entity: ScriptBase | ConstructBase) => boolean,
  ): void {
    entities.filter(entityPredicate).forEach((entity: ScriptBase | ConstructBase) => {
      if (entity instanceof Preview && entity.parent instanceof Attachment) {
        entitySet.add(entity.parent);
      }
      if (entity instanceof Attachment) {
        if (entity.preview) {
          entitySet.add(entity.preview);
        }
        return;
      }
      if (this.isConstructWithScript(entity?.parent)) {
        entitySet.add(entity.parent);
      }
      const children: EntityBase[] = entity.children;
      for (const child of children) {
        entitySet.add(child);
        entities.push(child);
      }
    });
  }

  private isConstructWithScript(entity?: EntityBase): boolean {
    return !!entity && entity instanceof ConstructBase && !!entity.script;
  }

  private get currentScopes() {
    return this.entityRepository.getScopes();
  }

  private getEntity(id: string) {
    return this.entityRepository.get(id, false);
  }

  private getNewPosition({
    node,
    pasteX,
    pasteY,
    minX,
    minY,
  }: {
    node: SerializedEntity;
    pasteX: number;
    pasteY: number;
    minX: number;
    minY: number;
  }) {
    const x = pasteX + (node.position.x - minX);
    const y = pasteY + (node.position.y - minY);
    return { x, y };
  }

  dispose() {
    this.clipboard.dispose();
  }
}
