import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { SyntheticEvent } from 'react';

export type SidebarTab = { label: string; value: SidebarTabValues };

export enum SidebarTabValues {
  Explorer = 'explorer',
  Assets = 'assets',
  Files = 'files',
}

export type SidebarTabsProps = {
  tabs: SidebarTab[];
  onChange: (newValue: SidebarTabValues) => void;
  value: string;
};

export const SidebarTabs = (props: SidebarTabsProps) => {
  const { tabs, onChange, value } = props;

  const handleOnChange = (event: SyntheticEvent, newTab: SidebarTabValues) => {
    onChange(newTab);
  };

  return (
    <Tabs
      value={value}
      onChange={handleOnChange}
      textColor="secondary"
      indicatorColor="secondary"
      variant="fullWidth"
      sx={{ width: '100%' }}
    >
      {tabs.map((tab) => (
        <Tab key={tab.value} label={tab.label} value={tab.value} disableRipple />
      ))}
    </Tabs>
  );
};
