import { CommandBase, IParams } from '../framework/CommandBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

export interface DeleteScriptFrameParams extends IParams {
  scriptId: string;
  frameId: string;
}

interface ScriptFrameDeletedParams extends IParams {
  scriptId: string;
  frameId: string;
}

export class ScriptFrameDeletedEvent extends EventBase {
  static eventType = 'ScriptFrameDeletedEvent';

  constructor(public readonly params: ScriptFrameDeletedParams, public readonly source = DeleteScriptFrameCommand) {
    super();
  }
}

export class DeleteScriptFrameCommand extends CommandBase<DeleteScriptFrameParams> {
  execute(params: DeleteScriptFrameParams): ScriptFrameDeletedEvent | CommandError {
    const script = this.model.entityRepository.get<ScriptBase>(params.scriptId)!;
    const entities = script.getEntitiesInFrameById(params.frameId);
    const entityIdsToDelete = entities.map((entity) => entity.id);
    if (!script.removeFrame(params.frameId)) return CommandError.of(script.getError()!, 'error');
    entityIdsToDelete.forEach((entityId) => this.model.entityRepository.delete(entityId));
    this.model.entityRepository.update(script);
    return new ScriptFrameDeletedEvent({
      scriptId: params.scriptId,
      frameId: params.frameId,
    });
  }
}
