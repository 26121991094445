import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';
import { z } from 'zod';

export class Data extends ConstructBase {
  style = { backgroundColor: '#C1E558', textColor: '#000000' };

  static schema = ConstructBase.baseSchema.extend({
    width: z.number().default(80),
  });

  get type(): EntityType {
    return EntityType.Data;
  }
}
