import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { AppSchemesBuilder } from '../../apps/AppSchemesBuilder';

export enum SchemeName {
  EventModelling = 'EVENT_MODELING',
  Narrative = 'NARRATIVE',
}

export class SchemeSetEvent extends EventBase {
  static eventType = 'SchemeSetEvent';

  constructor(public readonly params: CreateSchemeParams, public readonly source = SetSchemeCommand) {
    super();
  }
}

interface CreateSchemeParams extends IParams {
  scheme: SchemeName;
  spaceId: string;
  save?: boolean;
}

export class SetSchemeCommand extends CommandBase<CreateSchemeParams> {
  execute(params: CreateSchemeParams): EventBase | CommandError | undefined {
    const schemes = AppSchemesBuilder.Build(params.scheme);
    if (!schemes) {
      return CommandError.of(new Error('Scheme not found'), 'error');
    }
    // if (params.scheme === SchemeName.EventModelling) {
    //   schemes.forEach((scheme) => {
    //     DynamicEntityRegistry.registerScheme(scheme);
    //   });
    // }
    this.model.toolbar.clearSchemes();
    this.model.toolbar.addSchemes(schemes);
    return new SchemeSetEvent(params);
  }
}
