import { CommandBase, CommandConstructor, IParams } from './CommandBase';
import { EventBase, EventConstructor } from './EventBase';
import { EventHandlerBase } from './EventHandlerBase';
import { SingleSourceModel } from '../../SingleSourceModel';
import { logger } from '@xspecs/logger';
import { DEBUG_CONFIG } from '../../debug-config';
import { CommandError } from '../../ErrorStore';
import { Analytics } from '../../analytics/analytics';

const ANALYTICS_EXCLUDED_COMMANDS: readonly string[] = [
  'RegisterEntitiesCommand',
  'DeselectAllEntitiesCommand',
  'RecalculateDimensionsCommand',
  'BroadcastSavedChangesCommand',
  'HighlightDropTargetsCommand',
];

interface SubscribeEventHandler {
  execute: (event: EventBase) => void;
}

export class MessageBus {
  private commands: Map<CommandConstructor<CommandBase<IParams>>, CommandBase<IParams>> = new Map();
  private eventHandlers = new Map<EventConstructor<EventBase>, EventHandlerBase[]>();

  constructor(private readonly model: SingleSourceModel) {}

  registerCommand<T extends CommandBase<IParams>>(CommandClass: CommandConstructor<T>, commandInstance: T): void {
    this.commands.set(CommandClass, commandInstance);
  }

  registerEventHandler(EventClasses: EventConstructor<EventBase>[], handler: EventHandlerBase): void {
    EventClasses.forEach((EventClass) => {
      if (!this.eventHandlers.has(EventClass)) {
        this.eventHandlers.set(EventClass, []);
      }
      this.eventHandlers.get(EventClass)?.push(handler);
    });
  }

  subscribe<T extends EventBase>(eventClasses: EventConstructor<T>[], handlerFunction: (event: T) => void): void {
    eventClasses.forEach((EventClass) => {
      const inlineHandler: SubscribeEventHandler = {
        execute: handlerFunction,
      };
      if (!this.eventHandlers.has(EventClass)) {
        this.eventHandlers.set(EventClass, []);
      }
      this.eventHandlers.get(EventClass)!.push(inlineHandler as unknown as EventHandlerBase);
    });
  }

  // use this as the API entry point
  send<T extends IParams>(
    command: CommandConstructor<CommandBase<T>> | string,
    params: T,
    internal = false,
  ): void | Error {
    const commandClass = typeof command === 'string' ? this.getCommand(command) : command;
    if (!commandClass) return new Error(`Command ${command} not found`);
    if (DEBUG_CONFIG.messageBus) logger.log(`MessageBus.send: Sending command ${commandClass.name}`, params);
    const commandInstance = this.commands.get(commandClass);
    let result: CommandError | EventBase | void;
    if (!commandInstance) {
      logger.error(`Command ${commandClass.name} not found`);
      return new Error(`Command ${commandClass.name} not found`);
    }
    try {
      const commandName = commandInstance.constructor.name;
      if (!ANALYTICS_EXCLUDED_COMMANDS.includes(commandName)) {
        Analytics.getInstance().track({ event: commandName, params });
      }
      result = commandInstance.execute(params);
      if (result instanceof CommandError) {
        if (DEBUG_CONFIG.messageBus)
          logger.error(`MessageBus.send: Error executing command ${commandClass.name}, command resulted in:`, result);
        return this.storeAndReturnError(result).error;
      }
    } catch (error) {
      logger.error(`MessageBus.send: Error executing command ${commandClass.name}, command threw an error:`, error);
      return error;
    }
    if (result instanceof EventBase) {
      if (DEBUG_CONFIG.messageBus) logger.log(`MessageBus.send: Handling event ${result.constructor.name}`);
      this.triggerEventHandlers(result);
    }

    if (!internal && commandInstance.shouldSave()) {
      if (DEBUG_CONFIG.messageBus) logger.log(`MessageBus.send: Saving model`);
      this.model.entityRepository.save();
    }
  }

  private triggerEventHandlers(event: EventBase): void {
    const handlers = this.eventHandlers.get(event.constructor as EventConstructor<EventBase>) || [];

    if (DEBUG_CONFIG.messageBus)
      logger.log(
        `MessageBus.triggerEventHandlers: Found ${handlers.length} handler(s) for event ${event.constructor.name}`,
      );

    handlers.forEach((handler) => {
      try {
        handler.execute(event);
        if (DEBUG_CONFIG.messageBus)
          logger.log(`MessageBus.triggerEventHandlers: Handler ${handler.constructor.name} executed successfully`);
      } catch (error) {
        logger.error(`MessageBus.triggerEventHandlers: Error executing handler ${handler.constructor.name}`, error);
        this.storeAndReturnError(error as CommandError);
      }
    });
  }

  // use this in policies, do not use send or you'll get multiple saves
  sendInternal<T extends IParams>(CommandClass: CommandConstructor<CommandBase<T>>, params: T): void | Error {
    if (DEBUG_CONFIG.messageBus)
      logger.log(`MessageBus.sendInternal: Sending INTERNAL command ${CommandClass.name}`, params);
    return this.send(CommandClass, params, true);
  }

  handle(event: EventBase): Error | void {
    if (DEBUG_CONFIG.messageBus) logger.log(`MessageBus.handle: Handling event`, event);
    if (!event) return;
    const handlers = this.eventHandlers.get(event.constructor as EventConstructor<EventBase>);
    if (DEBUG_CONFIG.messageBus) logger.log(`MessageBus.handle: found ${handlers ? handlers.length : 0} handler(s)`);
    if (handlers && handlers.length)
      handlers.forEach((handler) => {
        if (DEBUG_CONFIG.messageBus) logger.log(`MessageBus.handle: calling ${handler.constructor.name}`);
        let result: void | Error;
        try {
          result = handler.execute(event);
        } catch (error) {
          logger.error(
            `MessageBus.send: Error executing policy ${handler.constructor.name}, policy threw an error:`,
            error,
          );
          return this.storeAndReturnError(error);
        }
        if (DEBUG_CONFIG.messageBus)
          logger.log(`MessageBus.handle: ${handler.constructor.name} executed with result`, result);
      });
  }

  getCommand(commandName: string): CommandConstructor<CommandBase<IParams>> | undefined {
    for (const [commandClass] of this.commands.entries()) {
      if (commandClass.name === commandName) {
        return commandClass as CommandConstructor<CommandBase<IParams>>;
      }
    }
    return undefined;
  }

  getEvent(eventName: string): EventConstructor<EventBase> | undefined {
    for (const [eventClass] of this.eventHandlers.entries()) {
      if ((eventClass as any).eventType === eventName) {
        return eventClass as EventConstructor<EventBase>;
      }
    }
    return undefined;
  }

  private storeAndReturnError(error: CommandError): CommandError {
    this.model.errorStore.addError(error);
    return error;
  }
}
