import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const StateView: FC<IconComponentProps> = ({ color, borderColor }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 11.3333V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V11.3333M14 4.66667V3.33333C14 2.97971 13.8595 2.64057 13.6095 2.39052C13.3594 2.14048 13.0203 2 12.6667 2H3.33333C2.97971 2 2.64057 2.14048 2.39052 2.39052C2.14048 2.64057 2 2.97971 2 3.33333V4.66667M3.33333 8C3.33333 8 5.03 4.66667 8 4.66667C10.9693 4.66667 12.6667 8 12.6667 8C12.6667 8 10.9693 11.3333 8 11.3333C5.03 11.3333 3.33333 8 3.33333 8ZM8 8.66667C8.17681 8.66667 8.34638 8.59643 8.4714 8.4714C8.59643 8.34638 8.66667 8.17681 8.66667 8C8.66667 7.82319 8.59643 7.65362 8.4714 7.5286C8.34638 7.40357 8.17681 7.33333 8 7.33333C7.82319 7.33333 7.65362 7.40357 7.5286 7.5286C7.40357 7.65362 7.33333 7.82319 7.33333 8C7.33333 8.17681 7.40357 8.34638 7.5286 8.4714C7.65362 8.59643 7.82319 8.66667 8 8.66667Z"
        stroke="#71717A"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
