import { CommandBase, IParams } from '../framework/CommandBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

export interface AddScriptFrameParams extends IParams {
  scriptId: string;
  frameId: string;
  position: 'before' | 'after';
}

interface ScriptFrameAddedParams extends IParams {
  scriptId: string;
  frameId: string;
  position: 'before' | 'after';
}

export class ScriptFrameAddedEvent extends EventBase {
  static eventType = 'ScriptFrameAddedEvent';

  constructor(public readonly params: ScriptFrameAddedParams, public readonly source = AddScriptFrameCommand) {
    super();
  }
}

export class AddScriptFrameCommand extends CommandBase<AddScriptFrameParams> {
  execute(params: AddScriptFrameParams): ScriptFrameAddedEvent | CommandError {
    const script = this.model.entityRepository.get<ScriptBase>(params.scriptId)!;
    if (!script.addFrameById(params.frameId, params.position)) return CommandError.of(script.getError()!, 'error');
    this.model.entityRepository.update(script);
    return new ScriptFrameAddedEvent({
      scriptId: params.scriptId,
      frameId: params.frameId,
      position: params.position,
    });
  }
}
