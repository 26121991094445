import { EntityType } from '../EntityType';
import { ScriptBase } from './ScriptBase';
import { ScriptConfig } from './ScriptConfig';
import { AllowedAction } from 'narrative-studio-sdk';

export class NarrativeScript extends ScriptBase {
  //static version = '1.0.1'; // Renames entity from Narrative to Script
  //static version = '1.0.2'; // Renames tags to labels
  //static version = '1.0.3'; // Renames tags to NarrativeScript

  get config(): ScriptConfig {
    return new ScriptConfig(
      [
        {
          allowedActions: { actions: [AllowedAction.ADD, AllowedAction.REMOVE] },
          frameGroupLimits: { min: 1, max: 1 },
          style: { backgroundColor: 'transparent', borderWidth: 1, borderColor: '#E4E4E8' },
          frameLimits: { min: 1, max: Infinity },
          defaultFrameWidth: 200,
          conflictingEntityGroups: [[EntityType.Interface, EntityType.Action]],
          frames: [{}, {}, {}, {}],
        },
      ],
      [
        {
          allowedActions: { actions: [AllowedAction.NONE] },
          laneGroupLimits: { min: 1, max: 1 },
          laneLimits: { min: 3, max: 3 },
          autoIngestInCorrectLane: true,
          defaultLaneHeight: 200,
          lanes: [
            {
              label: { text: 'Interaction' },
              allowedEntities: { type: 'SPECIFIC', entities: [EntityType.Interface] },
              entityLimits: { min: 0, max: 1 },
              style: { backgroundColor: '#F8F8F8' },
            },
            {
              label: { text: 'Context' },
              allowedEntities: { type: 'SPECIFIC', entities: [EntityType.Moment] },
              entityLimits: { min: 1, max: 1 },
              style: { backgroundColor: '#F5F0F8' },
            },
            {
              label: { text: 'System' },
              allowedEntities: { type: 'SPECIFIC', entities: [EntityType.Action] },
              entityLimits: { min: 0, max: 1 },
              style: { backgroundColor: '#F0E1F9' },
            },
          ],
        },
      ],
    );
  }

  get type(): EntityType {
    return EntityType.NarrativeScript;
  }

  // public entities(displayName: NarrativeScriptLaneNames): EntityBase[] {
  //   let allEntities: EntityBase[] = [];
  //   // first we need to find the lane index by displayName of the lane
  //   let laneIndex: number | undefined;
  //   this.lanes.forEach((lane, index) => {
  //     if (lane.label === displayName) {
  //       laneIndex = index;
  //     }
  //   });
  //   // If a matching lane index is found, we fetch entities from all frames at that lane index
  //   if (laneIndex !== undefined) {
  //     this.frames.forEach((frame) => {
  //       if (laneIndex! < frame.entities.length) {
  //         allEntities = allEntities.concat(frame.entities[laneIndex!]);
  //       }
  //     });
  //   }
  //   return allEntities;
  // }
}
