import { CommandBase, IParams } from '../framework/CommandBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

export interface DeleteScriptFrameGroupParams extends IParams {
  scriptId: string;
  frameGroupIndex: number;
}

interface ScriptFrameGroupDeletedParams extends IParams {
  scriptId: string;
  frameGroupIndex: number;
}

export class ScriptFrameGroupDeletedEvent extends EventBase {
  static eventType = 'ScriptFrameDeletedEvent';

  constructor(
    public readonly params: ScriptFrameGroupDeletedParams,
    public readonly source = DeleteScriptFrameGroupCommand,
  ) {
    super();
  }
}

export class DeleteScriptFrameGroupCommand extends CommandBase<DeleteScriptFrameGroupParams> {
  execute(params: DeleteScriptFrameGroupParams): ScriptFrameGroupDeletedEvent | CommandError {
    const script = this.model.entityRepository.get<ScriptBase>(params.scriptId)!;
    const frameGroup = script.frameGroups[params.frameGroupIndex];
    if (!frameGroup) return CommandError.of('Frame group not found', 'error');
    const framesToDelete = script.getFramesFromFrameGroup(params.frameGroupIndex);
    const entities = framesToDelete.flatMap((frame) => script.getEntitiesInFrameById(frame.id));
    const entityIdsToDelete = entities.map((entity) => entity.id);
    if (!script.removeFrameGroup(params.frameGroupIndex)) return CommandError.of(script.getError()!, 'error');
    entityIdsToDelete.forEach((entityId) => this.model.entityRepository.delete(entityId));
    this.model.entityRepository.update(script);
    return new ScriptFrameGroupDeletedEvent({
      scriptId: params.scriptId,
      frameGroupIndex: params.frameGroupIndex,
    });
  }
}
