import Clamp from 'react-multiline-clamp';
import { DragEventHandler, FC, useCallback, useRef } from 'react';
import { Box, List, MenuItem, Stack, SxProps, Theme, Typography } from '@mui/material';
import { CategoryToolbarElement, CategoryToolbarItemElement, ToolbarElementProps } from '@xspecs/single-source-model';
import { Icon } from '@xspecs/design-system';
import { useCommandDispatch } from '../../../wrappers/application-context/application-context';
import { isUrl } from '../../../lib/utils';

export const Category: FC<ToolbarElementProps<CategoryToolbarElement>> = (props) => {
  const { element } = props;

  const previewRefs = useRef<(SVGSVGElement | HTMLImageElement | null)[]>([]);
  const dispatch = useCommandDispatch();

  const onItemClick = useCallback(
    (item: CategoryToolbarItemElement) => () => {
      dispatch(item.onClick.command, item.onClick.params);
    },
    [dispatch],
  );

  const onItemDragStart = useCallback<(item: CategoryToolbarItemElement, index: number) => DragEventHandler>(
    (item, index) => (event) => {
      dispatch(item.onDragStart.command, item.onDragStart.params);
      const preview = previewRefs.current?.[index];
      if (preview) event.dataTransfer.setDragImage(preview, 0, 0);
    },
    [dispatch],
  );

  return (
    <Box gap={1.5} sx={rootSx}>
      <Typography variant="caption">{element.label}</Typography>
      <List sx={gridSx}>
        {element.elements.map((child, index) => (
          <MenuItem
            key={'Category' + index + child.type}
            disableRipple
            disableTouchRipple
            sx={itemSx}
            onClick={onItemClick(child)}
            onDragStart={onItemDragStart(child, index)}
            draggable
          >
            <Stack gap={1} direction="row">
              {'icon' in child ? (
                child.icon ? (
                  isUrl(child.icon) ? (
                    <img
                      ref={(node) => {
                        previewRefs.current[index] = node;
                      }}
                      alt={`${child.type}Icon`}
                      style={imgStyle}
                      src={child.icon}
                    />
                  ) : (
                    <Icon
                      ref={(node) => {
                        previewRefs.current[index] = node;
                      }}
                      name={child.icon}
                      color={child.iconColor}
                      iconBorder={child.iconBorder}
                    />
                  )
                ) : null
              ) : (
                <Icon
                  ref={(node) => {
                    previewRefs.current[index] = node as SVGSVGElement;
                  }}
                  name={child.shape}
                  color={child.iconColor}
                  iconBorder={child.iconBorder}
                />
              )}
              <Typography variant="body1">{child.label}</Typography>
              <Typography variant="body3" color="text.disabled">
                {child.subtitle}
              </Typography>
            </Stack>
            <Clamp lines={2}>
              <Typography variant="helperText" color="text.secondary">
                {child.description}
              </Typography>
            </Clamp>
          </MenuItem>
        ))}
      </List>
    </Box>
  );
};

const gridSx = {
  height: '100%',
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateRows: 'repeat(auto-fill, 80px)',
  overflowX: 'auto',
  overflowY: 'hidden',
  // gridTemplateColumns: 'repeat(auto-fit, minmax(0, auto))',
  gridTemplateColumns: 'auto auto',
};

const imgStyle = {
  width: 24,
  height: 24,
  minWidth: 24,
  minHeight: 24,
};

const rootSx: SxProps<Theme> = {
  height: '100%',
};

const itemSx: SxProps<Theme> = {
  height: '100%',
  width: '100%',
  p: 0,
  px: 1.5,
  py: 1,
  textWrap: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};
