import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';
import { z } from 'zod';
import { scriptBaseSchema } from '../scripts/ScriptBaseSchema';

export class Action extends ConstructBase {
  style = { backgroundColor: '#4a4a4a', textColor: 'white' };

  //static version = '2.0.0'; // renamed Component to Action;
  // static version = '2.0.3'; // re-structure of Scripts, cells and frames
  static version = '2.0.4'; // Adds edges to the script

  static schema = ConstructBase.baseSchema.extend({
    script: z.lazy(() => scriptBaseSchema),
  });

  get type(): EntityType {
    return EntityType.Action;
  }

  get scriptType(): string {
    return 'ActionScript';
  }

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
    };
  }
}
