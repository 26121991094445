import { ConstructBase } from './ConstructBase';
import { EntityType } from '../../entities/EntityType';
import { z } from 'zod';

export class Event extends ConstructBase {
  style = { backgroundColor: '#FF9C48', textColor: '#000000' };

  static schema = ConstructBase.baseSchema.extend({
    width: z.number().default(80),
  });

  get type(): EntityType {
    return EntityType.Event;
  }
}
