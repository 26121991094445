import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  SxProps,
  Typography,
  Tooltip,
  TooltipProps,
  Box,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Theme } from '@mui/system';
import { useIntl } from 'react-intl';
import { ExplorerItem } from '@xspecs/single-source-model';
import { AspectRatio, ExplorerChildItem } from './explorer-child-item';
import { memo, SyntheticEvent, useEffect, useState } from 'react';
import { FloatingPortal } from '@floating-ui/react';
import { FloatingMenu, useFloatingMenu } from '../../single-source-model/canvas/floating-menu/floating-menu';

type ExplorerAccordionProps = ExplorerItem;

const _ExplorerAccordion = (props: ExplorerAccordionProps) => {
  const { id, name, type, children, shape, isExpanded, style } = props;
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  const {
    context,
    floatingProps,
    floatingRef,
    floatingStyles,
    referenceProps,
    referenceRef,
    showFloating,
    arrowRef,
    onManageLabels,
  } = useFloatingMenu();

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  const { formatMessage: f } = useIntl();

  const hasChildren = children?.length > 0;

  const handleChange = (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion
      slotProps={{ heading: { component: 'h4' } }}
      disableGutters={true}
      sx={accordionSx}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={
          hasChildren ? (
            <Tooltip title={f({ id: 'show-usage' })} placement="top" slotProps={tooltipSlotProps}>
              <ExpandMore fontSize="small" color="action" />
            </Tooltip>
          ) : null
        }
        aria-controls={`explorer-item-${name}`}
        id={`explorer-item-${name}`}
        sx={accordionSummarySx}
        {...referenceProps}
        ref={referenceRef}
      >
        <Stack gap={1} direction="row" alignItems="center" sx={accordionContentSx}>
          <Tooltip title={name}>
            <Box
              height={10}
              className={type}
              sx={{
                aspectRatio: AspectRatio[shape],
                border: '1px solid',
                borderColor: 'action.disabled',
                mr: shape === 'square' ? 1 : 0,
                ...style,
              }}
            />
          </Tooltip>
          <Typography variant="body2" color="textPrimary" sx={assetNameSx}>
            {name}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={accordionDetailsSx} hidden={!hasChildren}>
        <Stack>
          {children.map((child) => (
            <ExplorerChildItem key={child.id} {...child} />
          ))}
        </Stack>
      </AccordionDetails>
      {showFloating ? (
        <FloatingPortal>
          <FloatingMenu
            ref={floatingRef}
            id={id}
            styles={floatingStyles}
            arrowRef={arrowRef}
            context={context}
            type={type}
            disableLabels
            name={name}
            onManageLabels={onManageLabels}
            labels={[]}
            {...floatingProps}
          />
        </FloatingPortal>
      ) : null}
    </Accordion>
  );
};

_ExplorerAccordion.displayName = 'ExplorerAccordion';
export const ExplorerAccordion = memo(_ExplorerAccordion);

const accordionSx: SxProps<Theme> = {
  boxShadow: 'none',
  background: (theme) => theme.palette.grey[50],
  position: 'relative',
  overflow: 'visible',
  '&:before': { display: 'none' },
};

const accordionDetailsSx: SxProps<Theme> = {
  pt: 0,
  pr: 2,
  pb: 0,
  pl: 0,
  ml: 3,
  mb: 1,
  borderLeft: '1px solid',
  borderColor: 'action.hover',
};

const tooltipSlotProps: TooltipProps['slotProps'] = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -14],
        },
      },
    ],
  },
};

const accordionSummarySx: SxProps<Theme> = {
  '& .MuiAccordionSummary-root': {
    justifyContent: 'space-between',
  },
  '& .MuiAccordionSummary-content': {
    width: '90%',
  },
};

const assetNameSx: SxProps<Theme> = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '80%',
};

const accordionContentSx: SxProps<Theme> = { width: '100%' };
