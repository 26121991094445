import { CommandBase, IParams } from '../framework/CommandBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface RenameFrameGroupParams extends IParams {
  scriptId: string;
  frameGroupIndex: number;
  value: string;
}

export interface FrameGroupRenamedParams extends IParams {
  scriptId: string;
  frameGroupIndex: number;
  value: string;
}

export class FrameGroupRenamedEvent extends EventBase {
  static eventType = 'FrameGroupRenamedEvent';

  constructor(public readonly params: FrameGroupRenamedParams, public readonly source = RenameFrameGroupCommand) {
    super();
  }
}

export class RenameFrameGroupCommand extends CommandBase<RenameFrameGroupParams> {
  execute(params: RenameFrameGroupParams): FrameGroupRenamedEvent | CommandError | undefined {
    const script = this.model.entityRepository.get<ScriptBase>(params.scriptId)!;
    const isRenamed = script.renameFrameGroup(params.frameGroupIndex, params.value);
    if (!isRenamed) return;
    this.model.entityRepository.update(script);
    return new FrameGroupRenamedEvent({
      scriptId: params.scriptId,
      frameGroupIndex: params.frameGroupIndex,
      value: params.value,
    });
  }
}
