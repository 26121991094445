import { z } from 'zod';
import { NarrativeScript } from './NarrativeScript';
import { EntityType } from '../EntityType';
import { ConstructBase } from '../constructs/ConstructBase';
import { scriptBaseSchema } from './ScriptBaseSchema';

export class Capability extends ConstructBase {
  style = { backgroundColor: '#E735BF', textColor: 'white' };

  static schema = ConstructBase.baseSchema.extend({
    scripts: z.array(scriptBaseSchema).optional().default([]),
    zIndex: z.number().default(5000),
  });

  get type(): EntityType {
    return EntityType.Capability;
  }

  static version = '1.0.1'; // Renames Tags to Labels;

  public readonly scripts: NarrativeScript[] = [];

  // static parse(data: unknown, omitReferences = false): Capability {
  //   const capability = super.parse<Capability>(data as object, omitReferences);
  //   if (capability.scripts.length > 0) {
  //     capability.scripts.forEach((script) => {
  //       script.parent = capability;
  //     });
  //   }
  //   return capability;
  // }

  isValid(): boolean {
    return Capability.schema.safeParse(this).success;
  }

  linkScript(script: NarrativeScript): void {
    script.hide();
    script.parent = this;
    this.scripts.push(script);
  }

  unLinkScript(script: NarrativeScript): void {
    script.parent = undefined;
    this.scripts.splice(this.scripts.indexOf(script), 1);
  }

  static references = ['scripts'];

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      id: this.id,
      scripts: this.scripts.map((entity) => entity.serialize(true)),
    };
  }
}
