import { MessageBus } from './framework/MessageBus';
import { SingleSourceModel } from '../SingleSourceModel';
import { ApplySavedFilterCommand } from './filters/ApplySavedFilterCommand';
import { DeleteFilterCommand } from './filters/DeleteFilterCommand';
import { RemoveFilterCriteriaCommand } from './filters/RemoveFilterCriteriaCommand';
import { SaveFiltersCommand } from './filters/SaveFiltersCommand';
import { SetUnsavedFilterCommand } from './filters/SetUnsavedFilterCommand';
import { UnapplySavedFilterCommand } from './filters/UnapplySavedFilterCommand';
import { UpdateSavedFiltersCommand } from './filters/UpdateSavedFiltersCommand';
import { MoveEntitiesCommand } from './entities/MoveEntitiesCommand';
import { CreateThreadCommand } from './comments/CreateThreadCommand';
import { ApplyFiltersCommand } from './filters/ApplyFiltersCommand';
import { AddEntitiesToLabelCommand } from './labels/AddEntitiesToLabelCommand';
import { CreateLabelCommand } from './labels/CreateLabelCommand';
import { DeleteLabelCommand } from './labels/DeleteLabelCommand';
import { RemoveEntitiesFromLabelCommand } from './labels/RemoveEntitiesFromLabelCommand';
import { UpdateLabelCommand } from './labels/UpdateLabelCommand';
import { RecalculateDimensionsCommand, RecalculateDimensionsPolicy } from './dimensions/RecalculateDimensionsCommand';
import { IngestCommandPolicy, IngestEntitiesCommand } from './entities/IngestEntitiesCommand';
import { AddEntityCommand } from './entities/AddEntityCommand';
import { DeleteEntitiesCommand } from './entities/DeleteEntitiesCommand';
import { ViewEntityDetailsCommand } from './entities/ViewEntityDetailsCommand';
import { RenameEntityCommand } from './entities/RenameEntityCommand';
import { CloseEntityDetailsCommand } from './entities/CloseEntityDetailsCommand';
import { DeselectAllEntitiesCommand } from './selections/DeselectAllEntitiesCommand';
import { AddScriptFrameCommand } from './scripts/AddScriptFrameCommand';
import { DeleteScriptFrameCommand } from './scripts/DeleteScriptFrameCommand';
import { AddScriptLaneCommand } from './scripts/AddScriptLaneCommand';
import { DeleteScriptLaneCommand } from './scripts/DeleteScriptLaneCommand';
import { UpdateEntitiesSelectionsCommand } from './selections/UpdateEntitiesSelectionsCommand';
import { ToggleScriptVisibilityCommand } from './scripts/ToggleScriptVisibilityCommand';
import { AddRelationshipCommand } from './relationships/AddRelationshipCommand';
import { AddCommentToThreadCommand } from './comments/AddCommentToThreadCommand';
import { ResolveThreadCommand } from './comments/ResolveThreadCommand';
import { UnresolveThreadCommand } from './comments/UnresolveThreadCommand';
import { LinkAssetToAttachmentCommand } from './attachments/LinkAssetToAttachmentCommand';
import { DragEntitiesCommand } from './entities/DragEntitiesCommand';
import { ClearErrorCommand } from './errors/ClearErrorCommand';
import { SelectAllEntitiesCommand } from './selections/SelectAllEntitiesCommand';
import { RenameScriptLaneCommand } from './scripts/RenameScriptLaneCommand';
import { CreateUploadCommand } from './entities/CreateUploadCommand';
import { ToggleResolvedThreadsVisibilityCommand } from './comments/ToggleResolvedThreadsVisibilityCommand';
import { ToggleAttachmentsExpandedCommand } from './attachments/ToggleAttachmentsExpanded';
import { ResizeEntityCommand } from './entities/ResizeEntityCommand';
import { HighlightDropTargetsCommand, HighlightDropTargetsPolicy } from './entities/HighlightDropTargetsCommand';
import { OpenEntityAnnotatorCommand } from './entities/OpenEntityAnnotatorCommand';
import { UpdateGqlEntityCommand } from './entities/UpdateGqlEntityCommand';
import { CloseEntityAnnotatorCommand } from './entities/CloseEntityAnnotatorCommand';
import { UpdateQueryUploadCommand } from './entities/UpdateQueryUploadCommand';
import { UpdateQueryTextCommand } from './entities/UpdateQueryTextCommand';
import { RegisterAppsCommand } from './apps/RegisterAppsCommand';
import { DeRegisterAppCommand } from './apps/DeRegisterAppCommand';
import { BroadcastSavedChangesCommand } from './changes/BroadcastSavedChangesCommand';
import { DeleteAssetCommand } from './entities/DeleteAssetCommand';
import { SetAssetExplorerSearchCommand } from './explorer/SetAssetExplorerSearchCommand';
import { SetAssetExplorerSortCommand } from './explorer/SetAssetExplorerSortCommand';
import { SetExplorerSortCommand } from './explorer/SetExplorerSortCommand';
import { SetExplorerSearchCommand } from './explorer/SetExplorerSearchCommand';
import { SetAssetExplorerScopeCommand } from './explorer/SetAssetExplorerScopeCommand';
import { SetToolbarStateCommand } from './toolbar/SetToolbarStateCommand';
import { AddToolbarSelectedEntityCommand } from './toolbar/AddToolbarSelectedEntityCommand';
import { UpdateRelationshipCommand } from './relationships/UpdateRelationshipCommand';
import { UpdateEdgeCommand } from './relationships/UpdateEdgeCommand';
import { PasteEdgeStyleCommand } from './relationships/PasteEdgeStyleCommand';
import { CreateSchemeCommand } from './apps/CreateSchemeCommand';
import { SetSchemeCommand } from './apps/SetSchemeCommand';
import { AddScriptFrameGroupCommand } from './scripts/AddScriptFrameGroupCommand';
import { DeleteScriptFrameGroupCommand } from './scripts/DeleteScriptFrameGroupCommand';
import { UpdateEntityPayloadCommand } from './entities/UpdateEntityPayloadCommand';
import { ApplyFileTreeChangesCommand } from './fileTree/ApplyFileTreeChangesCommand';
import { UpdateScriptFrameGroupCommand } from './scripts/UpdateScriptFrameGroupCommand';
import { RenameFrameGroupCommand } from './scripts/RenameFrameGroupCommand';

const commands = [
  // Entities
  MoveEntitiesCommand,
  DeleteEntitiesCommand,
  DeleteAssetCommand,
  IngestEntitiesCommand,
  AddEntityCommand,
  AddRelationshipCommand,
  UpdateRelationshipCommand,
  ViewEntityDetailsCommand,
  CloseEntityDetailsCommand,
  RenameEntityCommand,
  AddCommentToThreadCommand,
  ResolveThreadCommand,
  UnresolveThreadCommand,
  LinkAssetToAttachmentCommand,
  DragEntitiesCommand,
  HighlightDropTargetsCommand,
  RenameScriptLaneCommand,
  CreateUploadCommand,
  ToggleAttachmentsExpandedCommand,
  ResizeEntityCommand,
  ToggleResolvedThreadsVisibilityCommand,
  OpenEntityAnnotatorCommand,
  CloseEntityAnnotatorCommand,
  UpdateGqlEntityCommand,
  UpdateQueryUploadCommand,
  UpdateQueryTextCommand,
  UpdateEdgeCommand,
  PasteEdgeStyleCommand,
  UpdateEntityPayloadCommand,

  // Script Entities
  AddScriptFrameCommand,
  DeleteScriptFrameCommand,
  AddScriptLaneCommand,
  DeleteScriptLaneCommand,
  DeleteScriptFrameGroupCommand,
  ToggleScriptVisibilityCommand,
  AddScriptFrameGroupCommand,
  UpdateScriptFrameGroupCommand,
  RenameFrameGroupCommand,

  // Selections
  UpdateEntitiesSelectionsCommand,
  DeselectAllEntitiesCommand,
  SelectAllEntitiesCommand,

  // Errors
  ClearErrorCommand,

  // Dimensions
  RecalculateDimensionsCommand,

  // Comments
  CreateThreadCommand,

  // Labels
  CreateLabelCommand,
  UpdateLabelCommand,
  DeleteLabelCommand,
  AddEntitiesToLabelCommand,
  RemoveEntitiesFromLabelCommand,

  // Filters
  SetUnsavedFilterCommand,
  DeleteFilterCommand,
  RemoveFilterCriteriaCommand,
  SaveFiltersCommand,
  ApplySavedFilterCommand,
  UpdateSavedFiltersCommand,
  UnapplySavedFilterCommand,
  ApplyFiltersCommand,

  // Apps
  RegisterAppsCommand,
  DeRegisterAppCommand,
  CreateSchemeCommand,
  SetSchemeCommand,

  // changes
  BroadcastSavedChangesCommand,

  // Explorer
  SetAssetExplorerSearchCommand,
  SetAssetExplorerSortCommand,
  SetExplorerSortCommand,
  SetExplorerSearchCommand,
  SetAssetExplorerScopeCommand,

  //   Toolbar
  SetToolbarStateCommand,
  AddToolbarSelectedEntityCommand,

  // FileTree
  ApplyFileTreeChangesCommand,
];

const policies = [IngestCommandPolicy, RecalculateDimensionsPolicy, HighlightDropTargetsPolicy];

export function register(messageBus: MessageBus, model: SingleSourceModel): MessageBus {
  commands.forEach((CommandClass) => messageBus.registerCommand(CommandClass, new CommandClass(model)));
  policies.forEach((PolicyClass) => {
    const policyInstance = new PolicyClass(model);
    messageBus.registerEventHandler(policyInstance.handles(), policyInstance);
  });
  return messageBus;
}
