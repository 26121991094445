import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';

export class Constraints extends ConstructBase {
  style = { backgroundColor: '#FFF9B1', textColor: '#000000' };

  static version = '2.0.0'; // Replaces transitions with edges;

  get type(): EntityType {
    return EntityType.Constraints;
  }
}
