import { Icon } from '@xspecs/design-system';
import { Box, IconButton, SxProps, Theme, useTheme } from '@mui/material';
import { MouseEvent, useCallback } from 'react';
import { EntityType } from '@xspecs/single-source-model';
import { useApplication } from '../../../wrappers/application-context/application-context';

type ScriptToggleExpansionButtonProps = {
  id: string;
  rootSx?: SxProps<Theme>;
  className?: string;
  isExpanded: boolean;
  type: EntityType;
  onClick?: () => void;
};

export const ScriptToggleExpansionButton = (props: ScriptToggleExpansionButtonProps) => {
  const { id, rootSx, className, type, isExpanded, onClick: onClickProp } = props;

  const theme = useTheme();

  const { application } = useApplication();

  const onClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      application?.model.interactor.toggleEntityVisibilityExpanded(id);
      onClickProp?.();
    },
    [application?.model.interactor, id, onClickProp],
  );

  const name = type === EntityType.NarrativeScript ? 'script' : 'subscript';

  return (
    <Box
      className={className}
      bgcolor="background.paper"
      borderRadius="50%"
      sx={rootSx}
      data-id={`script-toggle-${id}`}
    >
      <IconButton onClick={onClick} size="small" sx={{ border: theme.palette.other.outlineBorder, p: 0.5 }}>
        <span style={{ position: 'relative', top: 2, left: 2 }}>
          <Icon name={isExpanded ? `collapse-${name}` : `expand-${name}`} width={20} height={20} />
        </span>
      </IconButton>
    </Box>
  );
};
