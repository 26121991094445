import { CommandBase, IParams } from '../framework/CommandBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

export interface UpdateScriptFrameGroupParams extends IParams {
  scriptId: string;
  frameGroupIndex: number;
  type: string;
}

interface ScriptFrameGroupUpdatedParams extends IParams {
  scriptId: string;
  frameGroupIndex: number;
  type?: string;
}

export class ScriptFrameGroupUpdatedEvent extends EventBase {
  static eventType = 'ScriptFrameGroupUpdatedEvent';

  constructor(
    public readonly params: ScriptFrameGroupUpdatedParams,
    public readonly source = UpdateScriptFrameGroupCommand,
  ) {
    super();
  }
}

export class UpdateScriptFrameGroupCommand extends CommandBase<UpdateScriptFrameGroupParams> {
  execute(params: UpdateScriptFrameGroupParams): ScriptFrameGroupUpdatedEvent | CommandError {
    const script = this.model.entityRepository.get<ScriptBase>(params.scriptId)!;
    script.updateFrameGroupType(params.frameGroupIndex, params.type);
    this.model.entityRepository.update(script);
    return new ScriptFrameGroupUpdatedEvent({
      scriptId: params.scriptId,
      frameGroupIndex: params.frameGroupIndex,
      type: params.type,
    });
  }
}
