import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const StateChange: FC<IconComponentProps> = ({ color, borderColor }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1626_5363)">
        <path
          d="M9.99992 7.99992V11.9999M7.99992 9.99992H11.9999M2.66659 10.6666C1.93325 10.6666 1.33325 10.0666 1.33325 9.33325V2.66659C1.33325 1.93325 1.93325 1.33325 2.66659 1.33325H9.33325C10.0666 1.33325 10.6666 1.93325 10.6666 2.66659M6.66659 5.33325H13.3333C14.0696 5.33325 14.6666 5.93021 14.6666 6.66659V13.3333C14.6666 14.0696 14.0696 14.6666 13.3333 14.6666H6.66659C5.93021 14.6666 5.33325 14.0696 5.33325 13.3333V6.66659C5.33325 5.93021 5.93021 5.33325 6.66659 5.33325Z"
          stroke="#71717A"
          strokeWidth="1.33"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1626_5363">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
