import { useIntl } from 'react-intl';
import { DocEditor } from '../../../doc/editor/doc-editor';
import { Spec } from '../../../spec/spec';
import { JsonEditor } from '../../entity-details/json-editor';
import { EntityDetailsEditor } from 'domain/single-source-model/src/read-models/entity-details/EntityDetails';
import { useApplication } from '../../../../wrappers/application-context/application-context';
import { UpdateEntityPayloadCommand } from '@xspecs/single-source-model';

type EntityDocProps = {
  entityId: string;
  editorType: EntityDetailsEditor;
};

export const EntityDescription = (props: EntityDocProps) => {
  const { entityId, editorType } = props;

  const { formatMessage: f } = useIntl();
  const { application } = useApplication();

  const id = `${entityId}-${editorType}`;
  switch (editorType) {
    case EntityDetailsEditor.GQL:
      return <Spec id={id} />;
    case EntityDetailsEditor.JSON:
      return (
        <JsonEditor
          id={id}
          onEditorChange={(value) => {
            application?.model.messageBus.send(UpdateEntityPayloadCommand, {
              entityId,
              payload: value,
            });
          }}
        />
      );
    case EntityDetailsEditor.SLATE:
    default:
      return <DocEditor documentId={id} placeholder={f({ id: 'description' })} />;
  }
};
