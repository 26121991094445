import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { memo, MouseEventHandler, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getRoutePathFromParams, RoutePaths } from '../../../config/route-paths/route-paths';
import { useNavigate, useParams } from 'react-router-dom';
import { useActiveOrganization } from '../../../hooks/use-active-organization';
import { useActiveWorkspace } from '../../../hooks/use-active-workspace';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { ConstructShape, ExplorerItem } from '@xspecs/single-source-model';
import { ExpandMore } from '@mui/icons-material';
import { FloatingMenu, useFloatingMenu } from '../../single-source-model/canvas/floating-menu/floating-menu';
import { FloatingPortal } from '@floating-ui/react';

export const AspectRatio: Record<ConstructShape, string> = {
  square: '1 / 1',
  rectangle: '1.75 / 1',
};

const _ExplorerChildItem = (props: ExplorerItem) => {
  const { id, name, type, shape, children, isHighlighted, isExpanded } = props;
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  const navigate = useNavigate();
  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();
  const setConstructToPanTo = useSingleSourceStore.use.setConstructToPanTo();
  const { entityId } = useParams();

  const {
    context,
    floatingProps,
    floatingRef,
    floatingStyles,
    referenceProps,
    referenceRef,
    showFloating,
    arrowRef,
    onManageLabels,
  } = useFloatingMenu();

  const handleChange = (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      if (!organization || !workspace) {
        return;
      }
      const path = getRoutePathFromParams(RoutePaths.Entity, {
        organizationName: organization?.name,
        workspaceName: workspace?.name,
        entityId: id,
      });

      setConstructToPanTo(id);
      navigate(path);
    },
    [id, navigate, organization, setConstructToPanTo, workspace],
  );

  const childSx: SxProps = useMemo(
    () => ({
      py: 0.5,
      pl: 3,
      pr: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 1,
      textAlign: 'left',
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      backgroundColor: entityId === id ? 'other.selectedEntity' : 'none',
    }),
    [entityId, id],
  );

  if (children.length === 0) {
    return (
      <Button key={name} sx={childSx} onClick={onClick}>
        <Box
          height={10}
          className={type}
          sx={{
            aspectRatio: AspectRatio[shape],
            border: '1px solid',
            borderColor: 'action.disabled',
            mr: shape === 'square' ? 1 : 0,
          }}
        />
        <Typography
          variant="caption"
          color={isHighlighted ? 'textPrimary' : 'textSecondary'}
          textTransform="none"
          sx={childNameSx}
          fontWeight={isHighlighted ? 500 : 400}
        >
          {name}
        </Typography>
      </Button>
    );
  }

  return (
    <Accordion
      slotProps={{ heading: { component: 'h4' } }}
      disableGutters={true}
      sx={accordionSx}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMore fontSize="small" color="action" sx={{ width: '16px' }} />}
        aria-controls={`explorer-item-${name}`}
        id={`explorer-item-${name}`}
        sx={accordionSummarySx}
        {...referenceProps}
        ref={referenceRef}
      >
        <Stack gap={1} direction="row" alignItems="center" sx={accordionContentSx}>
          <Tooltip title={name}>
            <Box
              height={10}
              className={type}
              sx={{
                aspectRatio: AspectRatio[shape],
                border: '1px solid',
                borderColor: 'action.disabled',
                mr: shape === 'square' ? 1 : 0,
              }}
            />
          </Tooltip>
          <Typography
            variant="caption"
            sx={childNameSx}
            color={isHighlighted ? 'textPrimary' : 'textSecondary'}
            fontWeight={isHighlighted ? 500 : 400}
          >
            {name}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={accordionDetailsSx}>
        <Stack>
          {children.map((child) => (
            <ExplorerChildItem key={child.id} {...child} />
          ))}
        </Stack>
      </AccordionDetails>
      {showFloating ? (
        <FloatingPortal>
          <FloatingMenu
            ref={floatingRef}
            id={id}
            styles={floatingStyles}
            arrowRef={arrowRef}
            context={context}
            type={type}
            disableLabels
            name={name}
            onManageLabels={onManageLabels}
            labels={[]}
            {...floatingProps}
          />
        </FloatingPortal>
      ) : null}
    </Accordion>
  );
};

_ExplorerChildItem.displayName = 'ExplorerChildItem';
export const ExplorerChildItem = memo(_ExplorerChildItem);

const childNameSx: SxProps<Theme> = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '80%',
  minHeight: '20px',
};

const accordionSx: SxProps<Theme> = {
  boxShadow: 'none',
  background: (theme) => theme.palette.grey[50],
  position: 'relative',
  overflow: 'visible',
  '&:before': { display: 'none' },
};

const accordionDetailsSx: SxProps<Theme> = {
  pt: 0,
  pr: 0,
  pb: 1,
  pl: 0,
  ml: 4,
  borderLeft: '1px solid',
  borderColor: 'action.hover',
};

const accordionSummarySx: SxProps<Theme> = {
  '&.MuiAccordionSummary-root': {
    justifyContent: 'space-between',
    minHeight: 'auto',
    py: 0.5,
    pl: 3,
    pr: 0,
  },
  '& .MuiAccordionSummary-content': {
    width: '90%',
    my: 0,
  },
};

const accordionContentSx: SxProps<Theme> = { width: '100%' };
