import { useCallback, useEffect, useState } from 'react';
import { OnMount } from '@monaco-editor/react';
import { MonacoBinding } from 'y-monaco';
import { PlaceholderContentWidget } from './placeholder-content-widget';
import { useApplication } from '../../wrappers/application-context/application-context';
import { singleSourceStore, useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { SpecFile, Status } from '@xspecs/single-source-model';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { logger } from '@xspecs/logger';
import { editor } from 'monaco-editor';
import { useWindowEvent } from '@mantine/hooks';

const MONACO_EDITOR_VIEW_STATE_KEY = 'monaco-editor-view-state';

export const useMonaco = ({
  id,
  placeholder,
  initialValue = '',
}: {
  id: string;
  placeholder: string;
  initialValue?: string;
}) => {
  const { organization } = useActiveOrganization();
  const { application } = useApplication();

  const [editor, setEditor] = useState<editor.IStandaloneCodeEditor | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  const fileId = `${organization?.id}/${id}`;
  const files = useSingleSourceStore.use.filesById();
  const file = files[fileId];

  const onMount = useCallback<OnMount>(
    (editor) => {
      application?.loadSpecCommand({ fileId, version: undefined });
      const file = singleSourceStore.getState().filesById[fileId].file as SpecFile;
      new PlaceholderContentWidget(placeholder, editor);
      const ytext = file.sharedType;
      const model = editor.getModel();
      if (!model) {
        logger.error('Editor model not found');
        return;
      }
      new MonacoBinding(ytext, model, new Set([editor]), file.awareness);
      setEditor(editor);
    },
    [application, fileId, placeholder],
  );

  useEffect(() => {
    if (editor && file?.status === Status.Synced && initialValue) {
      if (!editor.getModel()?.getValue()) {
        editor.getModel()?.setValue(initialValue);
      }
    }
  }, [file?.status, editor, initialValue]);

  const onUnmount = useCallback((id: string, editor: editor.IStandaloneCodeEditor) => {
    const state = editor?.saveViewState();
    if (!state) return;
    const current = localStorage.getItem(MONACO_EDITOR_VIEW_STATE_KEY) || '{}';
    const viewStates = JSON.parse(current);
    viewStates[id] = state;
    localStorage.setItem(MONACO_EDITOR_VIEW_STATE_KEY, JSON.stringify(viewStates));
  }, []);

  useEffect(() => {
    if (file?.status === Status.Synced && !isConnected) {
      setIsConnected(true);
    }
  }, [file?.status, isConnected]);

  useEffect(() => {
    if (!editor || !isConnected) return;

    const current = localStorage.getItem(MONACO_EDITOR_VIEW_STATE_KEY) || '{}';
    const viewStates = JSON.parse(current);
    const state = viewStates[id];
    if (state) {
      editor.restoreViewState(state);
    }

    return () => {
      if (!isConnected || !editor) return;
      const state = editor.saveViewState();
      const current = localStorage.getItem(MONACO_EDITOR_VIEW_STATE_KEY) || '{}';
      const viewStates = JSON.parse(current);
      viewStates[id] = state;
      localStorage.setItem(MONACO_EDITOR_VIEW_STATE_KEY, JSON.stringify(viewStates));
    };
  }, [editor, id, isConnected]);

  useWindowEvent('beforeunload', () => {
    if (editor && isConnected) {
      onUnmount(id, editor);
    }
  });

  return {
    onMount,
  } as const;
};
