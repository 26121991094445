import { EntityChanges } from '../../types';
import { EntityRepository } from '../../data/EntityRepository';
import { Edge } from '../../entities/transitions/Edge';
import { Edge as ReactFlowEdge, MarkerType, Node as ReactFlowNode } from '@xyflow/react';
import { IStore } from '../../data/Store';
import { DEBUG_CONFIG } from '../../debug-config';
import { logger } from '@xspecs/logger';
import { EntityType } from '../../entities/EntityType';
import { EntityBase } from '../../entities/EntityBase';
import { Attachment } from '../../entities/assets/Attachment';
import { BoundariesIndex } from '../boundary/BoundariesIndex';
import { AssetBase } from '../../entities/assets/AssetBase';
import { Label } from '../../entities/assets/Label';
import { Comment } from '../../entities/threads/Comment';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { Thread } from '../../entities/threads/Thread';
import { Z_INDEXES } from '../../ZIndexes';
import { Upload } from '../../entities/assets/Upload';
import { ConstructBase } from '../../entities/constructs/ConstructBase';
import { Filter } from '../../entities/Filter';
import { Preview } from '../../entities/assets/Preview';
import {
  AttachmentNodeData,
  CommonNodeData,
  ConstructNodeData,
  DebugNodeData,
  DropTargetEdgeData,
  EdgeData,
  PreviewNodeData,
  ScriptNodeData,
  ThreadNodeData,
} from './Graph.types';
import { ActiveUser } from '../../data/File.types';
import { DropTarget } from '../../commands/entities/HighlightDropTargetsCommand';
import { GqlEntityBase } from '../../entities/gql-entities/GqlEntityBase';
import { ScriptToScriptNodeTranslator } from './ScriptToScriptNodeTranslator';

type GraphPreferences = {
  showResolvedThreads: boolean;
};

export class Graph {
  nodesCache: Record<string, ReactFlowNode> = {};
  edgesCache: Record<string, ReactFlowEdge> = {};
  debugNodes: Record<string, ReactFlowNode> = {};
  dropTargets: Record<string, ReactFlowNode> = {};
  dropTargetEdges: Record<string, ReactFlowEdge> = {};
  dropTargetHighlights: Set<string> = new Set();
  nodeToEdgesCache: Record<string, string[]> = {};
  selectedEdgesCountCache: Set<string> = new Set();

  preferences: GraphPreferences = {
    showResolvedThreads: false,
  };

  constructor(
    private readonly entityRepository: EntityRepository,
    private readonly store: IStore,
    private readonly boundariesIndex: BoundariesIndex,
  ) {}

  public clear() {
    this.nodesCache = {};
    this.edgesCache = {};
    this.updateStore();
  }

  public drag(ids: string[], dxDy: { x: number; y: number }) {
    const idsSet = new Set(ids);
    ids.forEach((id) => {
      if (!dxDy) return;
      const node = this.nodesCache[id];
      if (!node) return;
      const newNode = structuredClone(node);
      const entity = this.entityRepository.get(node.id);
      if (!entity) {
        logger.error('Entity being dragged not found', node.id);
        return;
      }
      newNode.position.x = entity.position.x + dxDy.x;
      newNode.position.y = entity.position.y + dxDy.y;
      newNode.data.isDragging = true;
      newNode.dragging = true;

      const zIndex =
        entity instanceof ScriptBase
          ? Z_INDEXES.ScriptBeingDragged
          : entity instanceof Thread
          ? Z_INDEXES.ThreadBeingDragged
          : Z_INDEXES.ConstructBeingDragged;
      newNode.zIndex = zIndex;
      newNode.style = { ...newNode.style, zIndex: zIndex };
      this.nodesCache[id] = newNode;
    });
    Object.entries(this.edgesCache).forEach(([key, edge]) => {
      if (idsSet.has(edge.source) && idsSet.has(edge.target)) {
        const newEdge = structuredClone(edge);
        newEdge.zIndex = Z_INDEXES.ConstructEdgesDragged;
        newEdge.style = { ...newEdge.style, zIndex: Z_INDEXES.ConstructEdgesDragged };
        this.edgesCache[key] = newEdge;
      }
    });
    this.updateStore();
  }

  public updateGraph(changes: EntityChanges = { added: [], updated: [], deleted: [] }) {
    if (DEBUG_CONFIG.graph) logger.log('graph - changes', changes);
    changes.added.forEach((entity) => this.handleChange(entity));
    changes.updated.forEach((update) => this.handleChange(update.entity));
    changes.deleted.forEach((entity) => {
      if (entity.type === EntityType.Edge) {
        delete this.edgesCache[entity.id];
        this.selectedEdgesCountCache.delete(entity.id);
        Object.entries(this.nodeToEdgesCache).forEach(([key, value]) => {
          if (value.includes(entity.id)) {
            this.nodeToEdgesCache[key] = value.filter((edgeId) => edgeId !== entity.id);
          }
        });
      } else delete this.nodesCache[entity.id];
    });
    if (DEBUG_CONFIG.boundaries) this.showBoundaries();
    this.applyPreference();
  }

  public updatePreferences(preferences: Partial<GraphPreferences>) {
    this.preferences = { ...this.preferences, ...preferences };
    this.applyPreference();
  }

  private applyPreference() {
    Object.entries(this.nodesCache).forEach(([key, node]) => {
      if (
        node.type === EntityType.Thread &&
        this.entityRepository.get<Thread>(key) &&
        this.entityRepository.get<Thread>(key)!.isResolved &&
        !this.preferences.showResolvedThreads
      ) {
        delete this.nodesCache[key];
      }
    });
    if (this.preferences.showResolvedThreads) {
      this.entityRepository.list().forEach((entity) => {
        if (entity instanceof Thread && entity.isResolved) {
          this.nodesCache[entity.id] = this.entityToNode(entity);
        }
      });
    }
    this.updateStore();
  }

  private handleChange(entity: EntityBase) {
    if (entity instanceof Edge) {
      if (entity.isSelected) this.selectedEdgesCountCache.add(entity.id);
      else this.selectedEdgesCountCache.delete(entity.id);
    }
    // Reset edges if the node is moved
    if (this.nodeToEdgesCache[entity.id]) {
      const edgeIds = this.nodeToEdgesCache[entity.id] ?? [];
      edgeIds.forEach((edgeId) => {
        const edge = this.entityRepository.get(edgeId) as Edge;
        if (!edge) return logger.error("Edge doesn't exist", edgeId);
        this.edgesCache[edgeId] = this.entityToEdge(edge);
      });
    }

    if (entity instanceof AssetBase) {
      const parent = entity.parent ? this.entityRepository.get(entity.parent.id) : null; // This is because the asset will always have a parent because we are not filtering the relationships by scope. This entity repository get is a scoped get, this should fix the issue for now. Ideally we should have relationships per scope.
      if (parent instanceof Attachment) {
        const attachment = parent as Attachment;
        if (attachment.isVisible) {
          this.nodesCache[attachment.id] = this.entityToNode(attachment);
        } else {
          delete this.nodesCache[attachment.id];
        }
        if (entity instanceof Upload) {
          if (attachment.preview.isVisible) {
            this.nodesCache[attachment.preview.id] = this.entityToNode(attachment.preview);
          } else delete this.nodesCache[attachment.preview.id];
          return;
        }
        //this.nodesCache[entity.parent.id] = this.entityToNode(entity.parent);
      }
      delete this.nodesCache[entity.id];
      return;
    }
    if (
      entity instanceof AssetBase ||
      entity instanceof Label ||
      entity instanceof Comment ||
      entity instanceof Filter ||
      entity instanceof GqlEntityBase
    )
      return;
    if (!entity.isVisible) {
      delete this.nodesCache[entity.id];
      return;
    }
    if (entity instanceof ScriptBase && !entity.isOpen) {
      delete this.nodesCache[entity.id];
      return;
    }
    if (entity.type === EntityType.Edge) {
      const edge = entity as Edge;
      if (edge.source)
        this.nodeToEdgesCache[edge.source.id] = [...(this.nodeToEdgesCache[edge.source.id] || []), edge.id];
      if (edge.target)
        this.nodeToEdgesCache[edge.target.id] = [...(this.nodeToEdgesCache[edge.target.id] || []), edge.id];
      this.edgesCache[entity.id] = this.entityToEdge(edge);
    } else {
      this.nodesCache[entity.id] = this.entityToNode(entity);
    }
    if (entity instanceof Attachment) {
      if (entity.asset instanceof Upload) {
        if (entity.preview.isVisible) this.nodesCache[entity.preview.id] = this.entityToNode(entity.preview);
        else delete this.nodesCache[entity.preview.id];
      }
      // this.addEdgeToAttachment(entity);
    }
  }

  private updateStore() {
    this.store.getState().setGraph({
      nodes: Object.values(this.nodesCache).map((node) => {
        if (this.dropTargetHighlights.has(node.id)) {
          const className = node.className || '';
          return {
            ...node,
            className: className.includes('active') ? className : `${className} active`,
          };
        } else {
          const className = node.className || '';
          return {
            ...node,
            className: className.replace('active', ''),
          };
        }
      }),
      edges: Object.values(this.edgesCache),
    });
    this.store.getState().setShowResolvedThreads(this.preferences.showResolvedThreads);
  }

  entityToNode(entity: EntityBase): ReactFlowNode {
    const { id, type, position, width, height, isSelected, zIndex } = entity;
    const data = this.resolveDataForEntity(entity);

    return {
      id,
      type: entity instanceof ConstructBase ? 'Construct' : entity instanceof ScriptBase ? 'Script' : type,
      position: { x: position.x, y: position.y },
      width,
      height,
      zIndex,
      measured: { width, height },
      selected: isSelected,
      data: data,
      dragging: false,
      style: {
        width: width,
        height: height,
        zIndex: zIndex,
        padding: entity instanceof Thread || entity instanceof ScriptBase ? undefined : 5,
        border: !(entity instanceof Preview || entity instanceof Thread || entity instanceof ScriptBase)
          ? `1px dashed ${data.color}`
          : undefined,
        borderRadius: '4px',
        color: entity instanceof ConstructBase ? entity?.style?.textColor : 'black',
      },
      className: [entity.blink ? 'blink' : undefined].filter(Boolean).join(' '),
    } satisfies ReactFlowNode;
  }

  public highlightDropTarget(entityId: string) {
    this.dropTargetHighlights.add(entityId);
    this.updateStore();
  }

  private resolveDataForEntity(
    entity: EntityBase,
  ): CommonNodeData | ScriptNodeData | ConstructNodeData | AttachmentNodeData | ThreadNodeData | PreviewNodeData {
    const { isSelected, selectedBy } = entity;
    const borderColor = isSelected ? SELECTED_COLOR : getSelectedColor({ selectedBy, isOwnSelection: isSelected });
    const hasOverlay = checkHasOverlay({ isFiltered: entity.isFiltered, isSelected: entity.isSelected });
    const color = hasOverlay ? '#D9D9D9' : borderColor;

    const common: CommonNodeData = {
      type: entity.type,
      isFiltered: entity.isFiltered,
      labels: entity.labels,
      isDragging: false,
      hasScript: entity instanceof ConstructBase && entity.script !== undefined,
      name: entity.name,
      attributes: { fontSize: entity.attributes.fontSize },
      selectedBy: entity.selectedBy,
      isNew: entity.isNew,
      isFloating: !(entity.parent instanceof ScriptBase),
      hasOverlay,
      color,
      backgroundColor: entity instanceof ConstructBase ? entity?.style?.backgroundColor : undefined,
      border: entity instanceof ConstructBase ? entity.borderColor : undefined,
    };

    if (entity instanceof ScriptBase) return ScriptToScriptNodeTranslator.translate(entity, common);
    if (entity instanceof ConstructBase) {
      return {
        ...common,
        attachments: entity.attachments?.map((attachment) => ({
          id: attachment.id,
          height: attachment.height,
          subType: attachment.subType,
          attachmentsPadding: ConstructBase.attachmentsPadding,
        })),
        isExpanded: entity.script ? entity.script.isExpanded : undefined,
        scriptId: entity.script ? entity.script.id : undefined,
        isScriptVisible: entity.script?.isOpen ?? false,
      } satisfies ConstructNodeData;
    }

    if (entity instanceof Attachment) {
      return {
        ...common,
        subType: entity.subType,
        id: entity.id,
        asset: entity.asset,
        attachmentId: entity.id,
        hasParent: Boolean(entity.parent),
        iconUrl: entity.iconUrl,
        ...(entity.asset instanceof Upload && {
          url: entity.asset.url,
          uploadType: entity.asset.subType,
          metadata: {
            ...entity.asset.metadata,
          },
        }),
        assetListDataSource: entity.assetListDataSource.toString(),
      } satisfies AttachmentNodeData;
    }

    if (entity instanceof Thread) {
      const set = new Set(entity.comments.map((comment) => comment.createdBy));
      set.add(entity.createdBy); // this can never happen because we are deleting the thread if there are no comments
      return {
        ...common,
        comments: entity.comments,
        participantIds: Array.from(set),
        isResolved: entity.isResolved,
      } satisfies ThreadNodeData;
    }

    if (entity instanceof Preview && entity.parent instanceof Attachment && entity.parent.asset instanceof Upload) {
      return {
        ...common,
        name: entity.parent.asset.name,
        url: entity.parent.asset.url!,
        uploadType: entity.parent.asset.subType!,
        metadata: entity.parent.asset.metadata,
        selectedBy: entity.selectedBy,
        attachmentId: entity.parent.id,
        capabilities: {
          isClosable: entity.parent.parent instanceof ConstructBase && entity.parent?.isVisible,
        },
      } satisfies PreviewNodeData;
    }

    return common;
  }

  private entityToEdge(edge: Edge): ReactFlowEdge {
    const color = edge.isSelected ? SELECTED_COLOR : edge.color;

    return {
      id: edge.id,
      type: 'default',
      source: edge?.source?.id,
      target: edge?.target?.id,
      data: {
        name: edge.name,
        isReadOnly: false,
        toolbar: edge.toolbarLayout,
        lineStyle: edge.lineStyle,
        lineWeight: edge.lineWeight,
        labelColor: edge.labelColor,
        labelFontSize: edge.labelFontSize,
        lineType: edge.lineType,
        showLabel: this.selectedEdgesCountCache.size > 1 ? false : edge.isSelected,
      } satisfies EdgeData,
      zIndex: edge.source.zIndex,
      selected: edge.isSelected,
      sourceHandle: edge.sourceHandleLocation.toLowerCase(),
      targetHandle: edge.targetHandleLocation.toLowerCase(),
      style: {
        zIndex: edge.zIndex,
        strokeWidth: 3,
        stroke: color,
      },
      markerStart:
        edge.markerStart.type !== 'none'
          ? {
              type: this.edgeMarkerToMarkerType(edge.markerStart.type),
              strokeWidth: 3,
              color: color,
            }
          : undefined,
      markerEnd:
        edge.markerEnd.type !== 'none'
          ? {
              type: this.edgeMarkerToMarkerType(edge.markerEnd.type),
              strokeWidth: 3,
              color: color,
            }
          : undefined,
    } satisfies ReactFlowEdge;
  }

  dispose() {
    this.store.getState().setGraph({ nodes: [], edges: [] });
    this.nodesCache = {};
    this.edgesCache = {};
  }

  private showBoundaries() {
    Object.keys(this.debugNodes).forEach((key) => delete this.nodesCache[key]);
    this.boundariesIndex.getDebugNodes().forEach((boundary) => {
      const id = `${boundary.entityId}_boundary`;
      const measured = { width: boundary.maxX - boundary.minX, height: boundary.maxY - boundary.minY };
      this.nodesCache[id] = {
        id,
        type: 'DebugNode',
        position: { x: boundary.minX, y: boundary.minY },
        measured,
        ...measured,
        zIndex: Z_INDEXES.DebugNode,
        data: {
          id,
          name: boundary.entityId,
          type: boundary.scriptId ? EntityType.NarrativeScript : this.entityRepository.get(boundary.entityId)?.type,
          laneId: boundary.laneId!,
          frameId: boundary.frameId!,
          entity: this.entityRepository.get(boundary.entityId)!,
          ...measured,
        } satisfies DebugNodeData,
      };
      this.debugNodes[id] = this.nodesCache[id];
    });
  }

  private edgeMarkerToMarkerType(marker: string | undefined): MarkerType {
    switch (marker) {
      case 'arrowclosed':
        return MarkerType.ArrowClosed;
      case 'arrow':
      default:
        return MarkerType.Arrow;
    }
  }

  clearDropTargets() {
    Object.keys(this.dropTargets).forEach((key) => delete this.nodesCache[key]);
    Object.keys(this.dropTargetEdges).forEach((key) => delete this.edgesCache[key]);
    this.dropTargets = {};
    this.dropTargetEdges = {};
    this.dropTargetHighlights.clear();
  }

  clearDropTargetHighlights() {
    this.dropTargetHighlights.clear();
    this.updateStore();
  }

  addValidDropTarget(dropTarget: DropTarget) {
    const wantedId = `${dropTarget.entityId}_wantedDropTarget`;
    const width = dropTarget.wantedBoundedBox.maxX - dropTarget.wantedBoundedBox.minX;
    const height = dropTarget.wantedBoundedBox.maxY - dropTarget.wantedBoundedBox.minY;
    this.nodesCache[wantedId] = {
      id: wantedId,
      type: 'DropTargetNode',
      position: { x: dropTarget.wantedBoundedBox.minX, y: dropTarget.wantedBoundedBox.minY },
      zIndex: Z_INDEXES.DebugNode,
      width,
      height,
      measured: { width, height },
      data: {
        id: wantedId,
        width,
        height,
        entityType: this.entityRepository.get(dropTarget.entityId)?.type,
        subType: 'wanted',
      },
    };
    this.dropTargets[wantedId] = this.nodesCache[wantedId];

    const actualId = `${dropTarget.entityId}_actualDropTarget`;
    this.nodesCache[actualId] = {
      id: actualId,
      type: 'DropTargetNode',
      position: {
        x: dropTarget.actualBoundedBox?.minX ?? 0,
        y: dropTarget.actualBoundedBox?.minY ?? 0,
      },
      zIndex: Z_INDEXES.DebugNode,
      width,
      height,
      measured: { width, height },
      data: {
        id: actualId,
        width,
        height,
        entityType: this.entityRepository.get(dropTarget.entityId)?.type,
        subType: 'actual',
      },
    };
    this.dropTargets[actualId] = this.nodesCache[actualId];

    this.edgesCache[wantedId + '_' + actualId] = {
      id: wantedId + '_' + actualId,
      type: 'floating',
      source: wantedId,
      target: actualId,
      data: {
        isReadOnly: true,
        animated: true,
      } satisfies DropTargetEdgeData,
      zIndex: Z_INDEXES.DebugNode,
      selected: false,
      style: {
        zIndex: Z_INDEXES.DebugNode,
        strokeWidth: 4,
        stroke: 'rgba(0, 255, 0, 1)',
      },
      markerEnd: {
        type: MarkerType.Arrow,
        strokeWidth: 3,
        color: 'rgba(0, 255, 0, 0)',
      },
    } satisfies ReactFlowEdge;
    this.dropTargetEdges[wantedId + '_' + actualId] = this.edgesCache[wantedId + '_' + actualId];
  }

  addInvalidDropTarget(dropTarget: DropTarget) {
    const wantedId = `${dropTarget.entityId}_wantedDropTarget`;
    const width = dropTarget.wantedBoundedBox.maxX - dropTarget.wantedBoundedBox.minX;
    const height = dropTarget.wantedBoundedBox.maxY - dropTarget.wantedBoundedBox.minY;
    this.nodesCache[wantedId] = {
      id: wantedId,
      type: 'DropTargetNode',
      position: { x: dropTarget.wantedBoundedBox.minX, y: dropTarget.wantedBoundedBox.minY },
      zIndex: Z_INDEXES.DebugNode,
      width,
      height,
      measured: { width, height },
      data: {
        id: wantedId,
        width,
        height,
        entityType: this.entityRepository.get(dropTarget.entityId)?.type,
        subType: 'rejected',
      },
    };
    this.dropTargets[wantedId] = this.nodesCache[wantedId];
  }
}

function getSelectedColor({
  selectedBy,
  isOwnSelection,
}: {
  selectedBy: ActiveUser | undefined;
  isOwnSelection: boolean;
}): string {
  const unselectedColor = !isOwnSelection && selectedBy ? selectedBy.color : '#D9D9D9';
  return isOwnSelection ? SELECTED_COLOR : unselectedColor;
}
const SELECTED_COLOR = 'rgba(54, 148, 233, 1)';

type Params = { isFiltered: boolean; isSelected: boolean };
function checkHasOverlay(params: Params): boolean {
  return !params.isFiltered && !params.isSelected;
}
