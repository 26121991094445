import { EntityBase } from './EntityBase';
import { EntityType } from './EntityType';
import { ConstructBase } from './constructs/ConstructBase';
import { AssetBase } from './assets/AssetBase';
import { AppRegistry } from '../apps/AppRegistry';
import { EntityClassRegistry } from './EntityClassRegistry';
import { EntityTypeRegistry } from './EntityTypeRegistry';

function findAllParentClasses(cls: any): any[] {
  const parents: any[] = [];
  let currentClass = cls,
    max = 20;
  while (--max > 0) {
    const parentClass = Object.getPrototypeOf(currentClass.prototype);
    if (!parentClass || parentClass === Object.prototype) break;
    parents.push(parentClass.constructor);
    currentClass = parentClass.constructor;
  }
  return parents;
}

type AbstractConstructor<T = object> = abstract new (...args: any[]) => T;

export class EntityParserFactory {
  public static isInstanceOfBase<T extends EntityBase>(
    entityType: EntityType | string,
    baseClass: AbstractConstructor<T>,
  ): boolean {
    const entityClass = EntityClassRegistry.getConstructor(entityType);
    if (!entityClass) {
      throw new Error(`No class found for type ${entityType}`);
    }
    const parentClasses = findAllParentClasses(entityClass);
    return parentClasses.some((cls) => cls === baseClass);
  }

  public static getEntityClass(type: string): typeof EntityBase | undefined {
    if (!EntityClassRegistry.getConstructor(type)) throw new Error(`No parser found for type ${type}`);
    return EntityClassRegistry.getConstructor(type);
  }

  public static getEntityInstance(type: string): EntityBase | undefined {
    const entityClass = EntityClassRegistry.getConstructor(type);
    if (!entityClass) return undefined;
    return new entityClass();
  }

  public static getReferences(className: string): string[] {
    if (!EntityClassRegistry.getConstructor(className)) return [];
    const references = EntityClassRegistry.getConstructor(className).references;
    return [...new Set([...this.getParentReferences(className), ...references])];
  }

  private static getParentReferences(className: string) {
    function getReferenceFor(baseClassName: string) {
      // FIXME this is a hack to remove the underscore from the class name due to esbuild shitiness
      switch (baseClassName.replace('_EntityBase', 'EntityBase')) {
        case 'EntityBase':
          return EntityBase.references;
        case 'ConstructBase':
          return ConstructBase.references;
        case 'AssetBase':
          return AssetBase.references;
        default:
          return [];
      }
    }
    const entityClass = EntityClassRegistry.getConstructor(className);
    return findAllParentClasses(entityClass).flatMap((cls) => getReferenceFor(cls.name));
  }

  public static parse<T extends EntityBase>(
    data: any,
    entityType?: EntityType,
    findEntity?: (id: string) => EntityBase,
    omitReferences = false,
  ): T {
    // TODO replace with a decorator based approach
    EntityBase.setFindFunction(findEntity!);
    const typeKey = entityType || data.type;
    if (!typeKey) throw new Error('No type specified for parsing');
    let entityClass = EntityClassRegistry.getConstructor(typeKey);
    if (!entityClass && data.__dynamic) {
      entityClass = AppRegistry.createDynamicFallbackEntity(data);
      EntityTypeRegistry.register(data.type);
    }
    if (!entityClass) throw new Error(`No parser found for type ${typeKey}`);
    return entityClass.parse(data, omitReferences);
  }
}
