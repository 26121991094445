import { EntityBase } from '../EntityBase';
import { z } from 'zod';
import { Z_INDEXES } from '../../ZIndexes';

const frameSchema = z.object({
  id: z.string(),
  width: z.number(),
  entities: z.union([
    // Case 1: Accept EntityBase[][]
    z.array(z.array(EntityBase.abstractBaseSchema)),
    // Case 2: Accept [{ entities: EntityBase[] }]
    z.array(
      z.object({
        entities: z.array(EntityBase.abstractBaseSchema),
      }),
    ),
  ]),
});

export const scriptBaseSchema = EntityBase.abstractBaseSchema.extend({
  width: z.number().default(140),
  height: z.number().optional().default(80),
  isOpen: z.boolean().default(false),
  zIndex: z.number().default(Z_INDEXES.Script),
  frames: z.array(frameSchema).optional().default([]),
  lanes: z
    .array(
      z.object({
        id: z.string(),
        label: z.string().optional(),
        height: z.number(),
        color: z.string(),
      }),
    )
    .optional()
    .default([]),
  laneGroups: z
    .array(
      z.object({
        configIndex: z.number(),
        laneIds: z.array(z.string()),
      }),
    )
    .optional()
    .default([]),
  frameGroups: z
    .array(
      z.object({
        configIndex: z.number(),
        label: z.string(),
        frameIds: z.array(z.string()),
        type: z.string().optional(),
      }),
    )
    .optional()
    .default([]),
});
