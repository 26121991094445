import { CommandBase, IParams } from '../framework/CommandBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

export interface AddScriptFrameGroupParams extends IParams {
  scriptId: string;
  frameGroupIndex: number;
  position: 'before' | 'after';
  type?: string;
}

interface ScriptFrameGroupAddedParams extends IParams {
  scriptId: string;
  frameGroupIndex: number;
  position: 'before' | 'after';
  type?: string;
}

export class ScriptFrameGroupAddedEvent extends EventBase {
  static eventType = 'ScriptFrameGroupAddedEvent';

  constructor(
    public readonly params: ScriptFrameGroupAddedParams,
    public readonly source = AddScriptFrameGroupCommand,
  ) {
    super();
  }
}

export class AddScriptFrameGroupCommand extends CommandBase<AddScriptFrameGroupParams> {
  execute(params: AddScriptFrameGroupParams): ScriptFrameGroupAddedEvent | CommandError {
    const script = this.model.entityRepository.get<ScriptBase>(params.scriptId)!;
    if (!script.addFrameGroup(params.frameGroupIndex, params.position, params.type))
      return CommandError.of(script.getError()!, 'error');
    this.model.entityRepository.update(script);
    return new ScriptFrameGroupAddedEvent({
      scriptId: params.scriptId,
      frameGroupIndex: params.frameGroupIndex,
      position: params.position,
      type: params.type,
    });
  }
}
