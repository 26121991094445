import { useMutation } from '@apollo/client';
import { Box, Button, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ChangeEvent, FormEventHandler, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { ADD_WORKSPACE_MUTATION } from '../../graphql/mutations';
import { sid } from '@xspecs/short-id';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { SchemeName, SetSchemeCommand } from '@xspecs/single-source-model';
import { useApplication } from '../../wrappers/application-context/application-context';

export const CreateWorkspaceContent = () => {
  const [workspaceName, setWorkspaceName] = useState('');
  const [workspaceCreationError, setWorkspaceCreationError] = useState('');
  const [scheme, setScheme] = useState('');

  const { application } = useApplication();
  const { organization } = useActiveOrganization();
  const setShowCreateSpaceModal = useSingleSourceStore.use.setShowCreateSpaceModal();

  const { trackEvent } = useTrackEvents();

  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();

  const [addWorkspace, { client, loading }] = useMutation(ADD_WORKSPACE_MUTATION);

  const onWorkspaceNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setWorkspaceName(event.target.value);
    setWorkspaceCreationError('');
  }, []);

  const onCreateWorkspace = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      if (!organization) return;
      e.preventDefault();
      const workspaceId = sid();
      const response = await addWorkspace({
        variables: { args: { organizationId: organization?.id, workspaceId, workspaceName } },
      });
      const responseError = response.data?.addWorkspace?.error;
      if (responseError) {
        setWorkspaceCreationError(responseError);
      } else {
        application?.model.messageBus.send(SetSchemeCommand, {
          scheme: scheme as SchemeName,
          spaceId: workspaceId,
          save: true,
        });
        client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'organizations' });
        trackEvent(AppTypeEvent.WorkspaceCreated, { workspaceId, workspaceName, organizationId: organization?.id });
        setShowCreateSpaceModal(false);
        navigate(
          getRoutePathFromParams(RoutePaths.Workspace, {
            organizationName: organization?.name,
            workspaceName,
            fileId: '',
          }),
        );
      }
    },
    [
      organization,
      addWorkspace,
      workspaceName,
      application?.model.messageBus,
      scheme,
      client?.cache,
      trackEvent,
      setShowCreateSpaceModal,
      navigate,
    ],
  );

  const onSchemeSelect = useCallback((event: SelectChangeEvent<SchemeName>) => {
    setScheme(event.target.value);
  }, []);

  const onCancelClick = useCallback(() => {
    setShowCreateSpaceModal(false);
  }, [setShowCreateSpaceModal]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        p: 3,
        mx: { md: 'auto' },
        width: { md: '415px' },
        boxShadow: {
          md: ' 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
        },
        position: { md: 'absolute' },
        zIndex: { md: 1000 },
        top: { md: '50%' },
        left: { md: '50%' },
        transform: { md: 'translate(-50%, -50%)' },
        borderRadius: '8px',
      }}
    >
      <CloseOutlinedIcon
        sx={{ position: 'absolute', top: 16, right: 16, color: 'text.secondary', cursor: 'pointer' }}
        fontSize="small"
        onClick={onCancelClick}
      />
      <Box display="flex" alignItems="center" gap={1}>
        <AddCircleOutlineOutlinedIcon />
        <Typography variant="h6">{f({ id: 'create-new-space' })}</Typography>
      </Box>
      <Typography variant="subtitle2" color="text.secondary">
        {f({ id: 'your-new-space-needs-a-name' })}
      </Typography>
      <form onSubmit={onCreateWorkspace}>
        <Typography variant="subtitle2" color="text.primary" sx={{ mt: 2 }}>
          {f({ id: 'space-name' })}
        </Typography>
        <TextField
          autoFocus
          fullWidth
          sx={{ mb: 2, '.MuiFormHelperText-root': { mx: 0 }, '.MuiInputBase-input': { py: 1 } }}
          placeholder={f({ id: 'space-name' })}
          onChange={onWorkspaceNameChange}
          error={Boolean(workspaceCreationError)}
          helperText={workspaceCreationError ? f({ id: workspaceCreationError }) : ''}
        />
        <Typography variant="subtitle2" color="text.primary">
          {f({ id: 'scheme' })}
        </Typography>
        <Select
          fullWidth
          size="small"
          value={scheme}
          onChange={onSchemeSelect}
          sx={{ mb: 2 }}
          placeholder="osama"
          displayEmpty
          renderValue={(value: unknown) => {
            if (!value) {
              return (
                <Typography color="#a2a2a2" fontSize="15px">
                  {f({ id: 'scheme' })}
                </Typography>
              );
            }
            return <>{f({ id: value as string })}</>;
          }}
        >
          <MenuItem value={SchemeName.EventModelling}>{f({ id: SchemeName.EventModelling })}</MenuItem>
          <MenuItem value={SchemeName.Narrative}>{f({ id: SchemeName.Narrative })}</MenuItem>
        </Select>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1.5 }}>
          <Button variant="text" onClick={onCancelClick} sx={{ minWidth: 'auto', py: 1 }}>
            {f({ id: 'cancel' })}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ minWidth: 'auto', py: 1, textTransform: 'none' }}
            disabled={!workspaceName || !scheme}
            loading={loading}
          >
            {f({ id: 'create-this-space' })}
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};
