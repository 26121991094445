import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';

export class Projection extends ConstructBase {
  style = { backgroundColor: '#FA8A7B', textColor: '#000000' };

  get type(): EntityType {
    return EntityType.Projection;
  }
}
