import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { memo, MouseEventHandler, useCallback, useMemo } from 'react';
import { getRoutePathFromParams, RoutePaths } from '../../../config/route-paths/route-paths';
import { useNavigate, useParams } from 'react-router-dom';
import { useActiveOrganization } from '../../../hooks/use-active-organization';
import { useActiveWorkspace } from '../../../hooks/use-active-workspace';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { AssetExplorerUsageItem } from '@xspecs/single-source-model/src/read-models/asset-explorer/AssetExplorerItem';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { AspectRatio } from '../explorer/explorer-child-item';

const _AssetUsageItem = (props: AssetExplorerUsageItem) => {
  const { id, name, type, shape, scopes } = props;

  const navigate = useNavigate();
  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();
  const setConstructToPanTo = useSingleSourceStore.use.setConstructToPanTo();
  const { entityId } = useParams();

  const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    if (!organization || !workspace) {
      return;
    }
    const organizationName = organization.name;
    const entityWorkspace = organization.workspaces.find((workspace) => scopes.includes(workspace.id));

    const path = getRoutePathFromParams(RoutePaths.Entity, {
      organizationName: organizationName,
      workspaceName: entityWorkspace?.name || workspace.name,
      entityId: id,
    });

    if (entityWorkspace?.id === workspace?.id) {
      setConstructToPanTo(id);
      navigate(path);
    } else {
      window.open(window.location.origin + path);
    }
  }, [organization, workspace, id, scopes, setConstructToPanTo, navigate]);

  const usageSx: SxProps = useMemo(
    () => ({
      py: 0.5,
      pl: 3,
      pr: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 1,
      borderLeft: '1px solid',
      borderColor: entityId === id ? 'primary.main' : 'action.hover',
      textAlign: 'left',
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      backgroundColor: entityId === id ? 'other.selectedEntity' : 'none',
    }),
    [entityId, id],
  );

  const showOpenInNewTab = !scopes.includes(workspace?.id ?? '');

  return (
    <Button key={id} sx={usageSx} onClick={onClick}>
      <Box
        height={10}
        className={type}
        sx={{
          aspectRatio: AspectRatio[shape],
          border: '1px solid',
          borderColor: 'action.disabled',
          mr: shape === 'square' ? 1 : 0,
        }}
      />
      <Typography variant="caption" color="textSecondary" textTransform="none" sx={usageNameSx}>
        {name}
      </Typography>
      {showOpenInNewTab ? <OpenInNewIcon fontSize="small" color="action" /> : null}
    </Button>
  );
};

_AssetUsageItem.displayName = 'AssetUsageItem';
export const AssetUsageItem = memo(_AssetUsageItem);

const usageNameSx: SxProps<Theme> = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '80%',
};
