import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const Automation: FC<IconComponentProps> = ({ color, borderColor }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66667 7.33333V10C4.66667 10.3536 4.80714 10.6928 5.05719 10.9428C5.30724 11.1929 5.64638 11.3333 6 11.3333H8.66667M3.33333 2H6C6.73638 2 7.33333 2.59695 7.33333 3.33333V6C7.33333 6.73638 6.73638 7.33333 6 7.33333H3.33333C2.59695 7.33333 2 6.73638 2 6V3.33333C2 2.59695 2.59695 2 3.33333 2ZM10 8.66667H12.6667C13.403 8.66667 14 9.26362 14 10V12.6667C14 13.403 13.403 14 12.6667 14H10C9.26362 14 8.66667 13.403 8.66667 12.6667V10C8.66667 9.26362 9.26362 8.66667 10 8.66667Z"
        stroke="#71717A"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
